import {isFSA} from './isFSA';

import {hideLoader, showLoader} from 'src/state/slices/commonSlice';
import {LOADER_IDS} from 'src/common/constants/loaderIds';
import Logger from 'src/common/utils/Logger';

const isPromise = (val) => val && typeof val.then === 'function';

const loadingShowHide = (dispatch, action) => {
  Object.keys(LOADER_IDS).forEach((key) => {
    console.log('action.type=>', action.type, key);
    if (action.type.includes(LOADER_IDS[key])) {
      if (action.type.includes('pending')) {
        dispatch(showLoader());
      } else {
        dispatch(hideLoader());
      }
    }
  });
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export default ({dispatch}) =>
  (next) =>
  (action) => {
    let functionResult;
    if (action) {
      if (isFSA(action)) {
        loadingShowHide(dispatch, action);
        functionResult = isPromise(action.payload)
          ? // @ts-ignore
            action.payload.then(
              (result) => {
                dispatch(hideLoader());
                return dispatch({...action, payload: result});
              },
              (error) => {
                dispatch(hideLoader());
                dispatch({...action, payload: error, error: true});
                Logger.error('Network error', error);
              },
            )
          : next(action);
      } else {
        Logger.warn('This action is not FSA compatible!', action);
        functionResult = isPromise(action)
          ? action.then(dispatch)
          : next(action);
      }
    } else {
      Logger.warn('Undefined action!');
      functionResult = next(action);
    }

    return functionResult;
  };
