import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import AuthService from 'src/common/services/AuthService';
import { parseUsernameInJWT } from 'src/common/utils/Utils';

export interface UserState {
  username?: string;
  token?: string;
  isLoggedin?: boolean;
  error?: string | unknown;
}

const INITIAL_STATE: UserState = {
  username: '',
  isLoggedin: AuthService.getCurrentUserToken() ? true : false,
  error: '',
};

export const LOGIN_FAILED = 'login_failed';

export const login = createAsyncThunk(
  'auth/login',
  async (
    userdto: { username: string; password: string },
    { rejectWithValue },
  ) => {
    try {
      // @ts-ignore
      const loginResponse: any = await AuthService.login(
        userdto.username,
        userdto.password,
      );
      return loginResponse;
    } catch (e) {
      return rejectWithValue(LOGIN_FAILED);
    }
  },
);

export const authSlice = createSlice({
  name: 'auth',
  initialState: INITIAL_STATE,
  reducers: {
    logout: (mutableState) => {
      mutableState.isLoggedin = false;
      delete mutableState.username;
      delete mutableState.token;
      AuthService.removeCurrentUserToken();
    },
    loginAfterRegister: (mutableState, action) => {
      localStorage.setItem('user', action.payload);
      mutableState.isLoggedin = true;
      mutableState.token = action.payload.token;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(login.fulfilled, (state, action) => {
      state.isLoggedin = true;
      state.token = action.payload.token;
      // state.username = action.payload.username;
      state.username = parseUsernameInJWT(action.payload.token);
    });
    builder.addCase(login.rejected, (state, action) => {
      state.isLoggedin = false;
      state.error = action.payload;
      delete state.username;
      delete state.token;
    });
  },
});

const { actions, reducer } = authSlice;
export const { logout, loginAfterRegister } = actions;
export { actions, reducer };
