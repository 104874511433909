import {createSlice} from '@reduxjs/toolkit';

const commonSlice = createSlice({
  name: 'common',
  initialState: {
    showLoader: true,
  },
  reducers: {
    showLoader: (mutableState) => {
      mutableState.showLoader = true;
    },
    hideLoader: (mutableState) => {
      mutableState.showLoader = false;
    },
  },
});

export const {reducer, actions} = commonSlice;
export const {showLoader, hideLoader} = actions;
