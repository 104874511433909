interface Action {
  type: string;
  error?: boolean;
  meta?: object; // eslint-disable-line @typescript-eslint/ban-types
}

function isValidKey(key: string) {
  return ['type', 'payload', 'error', 'meta'].indexOf(key) > -1;
}

export function isFSA(action: Action): boolean {
  return (
    action &&
    typeof action === 'object' &&
    (typeof action.type === 'string' || action instanceof String) &&
    Object.keys(action).every(isValidKey)
  );
}

export function isError(action: Action): boolean {
  return isFSA(action) && action.error === true;
}
