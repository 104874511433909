import React from 'react';
import { Button, Modal } from 'reactstrap';

interface InfoModalProps {
  onModalToggle: (modalParam) => void;
  isOpen: boolean;
  modalTitle: string;
  modalContent: string;
}

const InfoModal: React.FC<InfoModalProps> = (
  props: InfoModalProps,
): JSX.Element => {
  const { onModalToggle, isOpen, modalTitle, modalContent } = props;

  return (
    <Modal
      className="modal-dialog-centered"
      isOpen={isOpen}
      toggle={onModalToggle}>
      <div className="modal-header">
        <h5 className="modal-title" id="exampleModalLabel">
          {modalTitle}
        </h5>
        <button
          aria-label="Close"
          className="close"
          data-dismiss="modal"
          type="button"
          onClick={onModalToggle}>
          <span aria-hidden={true}>×</span>
        </button>
      </div>

      <div className="modal-body">
        <p>{modalContent}</p>
      </div>

      <div className="modal-footer">
        <Button
          className="ml-auto"
          color="link"
          data-dismiss="modal"
          type="button"
          onClick={onModalToggle}>
          Kapat
        </Button>
      </div>
    </Modal>
  );
};

export default InfoModal;
