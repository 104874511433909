import React from 'react';
import {connect, ConnectedProps} from 'react-redux';
import {bindActionCreators} from '@reduxjs/toolkit';
import {
  Button,
  Col,
  Form,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Row,
} from 'reactstrap';
import {BsFillKeyFill} from 'react-icons/bs';

import {handleInvalidInput} from 'src/common/utils/Utils';
import {
  organizationMailConfirm,
  organizationMailConfirmSubmit,
  setNotificationInfo,
} from 'src/state/slices/verificationSlice';
import {
  IOrganizationMailConfirmDto,
  IValidateVerificationEmailDto,
} from 'types/verificationTypes';

type OrganizationMailSendFormProps = PropsFromRedux & {
  organizationEmail: string;
};

const OrganizationMailSendForm: React.FC<OrganizationMailSendFormProps> = (
  prop: OrganizationMailSendFormProps,
): JSX.Element => {
  const {
    organizationMailConfirmSubmit,
    organizationMailConfirm,
    showLoader,
    verificationInfo,
    // @ts-ignore
    organizationEmail,
  } = prop;

  const onFormSubmitHandler = (event) => {
    event.preventDefault();
    const data = new FormData(event.target);
    const validateVerificationEmailDto: IValidateVerificationEmailDto = {
      verificationId: verificationInfo.id || '',
      validationCode: data.get('validationCode') as string,
    };
    console.log('validateVerificationEmailDto', validateVerificationEmailDto);
    organizationMailConfirmSubmit(validateVerificationEmailDto);
  };


  const handleResendValidationCodeSubmit = (event) => {
    event.preventDefault();
    const sendVerificationEmailDto: IOrganizationMailConfirmDto = {
      email: verificationInfo.verificationByEmailList[0].email,
      verificationId: verificationInfo?.id || '',
    };
    organizationMailConfirm({
      organizationMailConfirmDto: sendVerificationEmailDto,
    });
  };

  return (
    <>
      <Form role="form" onSubmit={onFormSubmitHandler}>
        <FormGroup className="mb-3">
          <div className="text-muted text-left mb-3">
            <small>
              Mail adresinize gelen 6 haneli doğrulama kodu ile
              mailinizi doğrulayın
            </small>
          </div>
          <InputGroup className="input-group-alternative">
            <InputGroupAddon addonType="prepend">
              <InputGroupText>
                <BsFillKeyFill style={{fontSize: '1.1rem'}} />
              </InputGroupText>
            </InputGroupAddon>
            <Input
              placeholder="Doğrulama kodu"
              type="text"
              name="validationCode"
              minLength="6"
              maxLength="6"
              required="required"
              onInput={handleInvalidInput}
              onInvalid={handleInvalidInput}
            />
          </InputGroup>
        </FormGroup>

        <Row className="my-4">
          <Col xs="12">
            <div className="text-center">
              <Button
                disabled={showLoader}
                color="primary"
                type="submit"
                style={{width: '75%'}}>
                Doğrula
              </Button>
            </div>
          </Col>
        </Row>
      </Form>
      <Form role="form" onSubmit={handleResendValidationCodeSubmit}>
        <Row>
          <Col xs="12">
            <div className="text-center">
              <Button
                outline
                disabled={showLoader}
                color="secondary"
                type="submit"
                style={{width: '75%'}}>
                Kodu Yeniden Gönder
              </Button>
            </div>
          </Col>
        </Row>
      </Form>

      <div className="text-muted text-left mb-3">
        <small>Doğrulama kodunun geçerlilik süresi 10dk dır</small>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  verificationInfo: state.verification.verificationInfo,
  showLoader: state.common.showLoader,
  notificationInfo: state.verification.notificationInfo,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      organizationMailConfirmSubmit,
      setNotificationInfo,
      organizationMailConfirm,
    },
    dispatch,
  );

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(OrganizationMailSendForm);
