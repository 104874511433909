import React from 'react';

import {Button, Col, Form, FormGroup, Input, InputGroup, Row} from 'reactstrap';

import {handleInvalidInput} from '../../common/utils/Utils';
import {IVerification} from 'types/verificationTypes';

interface VerificationDocumentUploadFormProps {
  showLoading: boolean;
  verificationInfo: IVerification;
  handleDocumentUploadSubmit: (event) => void;
  setSelectedFiles: (event) => void;
}

const VerificationDocumentUploadForm: React.FC<
  VerificationDocumentUploadFormProps
> = (prop: VerificationDocumentUploadFormProps): JSX.Element => {
  const {
    handleDocumentUploadSubmit,
    setSelectedFiles,
    showLoading,
    verificationInfo,
  } = prop;

  // TODO: Birden fazla seçebilme durumu eklenmeli
  // const [selectedFiles, setSelectedFiles] = useState(null);
  const handleFileSelected = (e) => {
    setSelectedFiles(e.target.files);
  };

  return (
    <Form role="form" onSubmit={handleDocumentUploadSubmit}>
      <FormGroup className="mb-3">
        <div className="text-muted text-center mb-3">
          <small>
            <>
              {verificationInfo?.selectedOrganization?.name ||
                verificationInfo?.selectedOrganization}{' '}
              kurumunun üyesi olduğunuzu kanıtlamak için kimliğini ve
              fotoğrafınızı sistemimize yüklemeniz gerekmektedir.
            </>
          </small>
        </div>
        <InputGroup className="input-group-alternative">
          <Input
            className="form-control"
            required="required"
            type="file"
            onChange={handleFileSelected}
            onInput={handleInvalidInput}
            onInvalid={handleInvalidInput}
          />
        </InputGroup>
      </FormGroup>

      <Row className="my-4">
        <Col xs="12">
          <div className="text-center">
            <Button
              disabled={showLoading}
              className="my-4"
              color="primary"
              type="submit">
              Belgelerimi Yükle
            </Button>
          </div>
        </Col>
      </Row>
    </Form>
  );
};

export default VerificationDocumentUploadForm;
