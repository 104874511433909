import {
  Alert,
  Button,
  Col,
  Form,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Row,
  Label,
} from 'reactstrap';
import { handleInvalidInput } from 'src/common/utils/Utils';
import ProfileService from 'src/common/services/ProfileService';
import React, { useEffect, useState } from 'react';
import { AiTwotonePhone } from 'react-icons/ai';
import { BsFillPersonFill } from 'react-icons/bs';
import { formatDate } from 'src/common/utils/Utils';
import { useTranslation } from 'src/common/i18n/I18n';
import LoadingForm from '../Loading/LoadingForm';
import { logout } from 'src/state/slices/authSlice';

const ProfileForm = (): JSX.Element => {
  const { t } = useTranslation();

  // INITIALIZATION
  const [notification, setNotification] = useState({
    notificationText: '',
    notificationType: 'danger',
    notificationShow: false,
  });
  const [progressProperties, setProgressProperties] = useState({
    waitForProcess: false,
  });

  const [user, updateUser] = useState({
    username: '',
    firstName: '',
    lastName: '',
    phoneNumber: '',
    birthDate: '',
    gender: '',
  });

  // Fetch user from service & display on screen
  const fetchUserData = async () => {
    const response: Response = await ProfileService.getUserInfo();
    if (response.ok) {
      const data = await response.json();
      data['birthDate'] = formatDate(new Date(data['birthDate']));
      updateUser(data);
    } else {
      setNotification({
        notificationText:
          'Profil bilgisinde beklenmedik bir hata ile karşılaşıldı...' +
          'Giriş sayfasına yönlendirileceksiniz',
        notificationType: 'danger',
        notificationShow: true,
      });
      logout();
    }
    setProgressProperties({ waitForProcess: true });
  };

  useEffect(() => {
    setProgressProperties({ waitForProcess: false });
    fetchUserData();
  }, []);

  const onChange = (e) => {
    updateUser({ ...user, [e.target.name]: e.target.value });
  };

  // SUBMIT REGISTRATION FORM
  const handleSubmit = async (event) => {
    event.preventDefault();
    const userdto = {
      firstName: user.firstName,
      lastName: user.lastName,
      phoneNumber: user.phoneNumber,
      birthDate: user.birthDate,
      gender: user.gender,
    };
    const response: Response = await ProfileService.updateUser(userdto);
    if (response.ok) {
      setNotification({
        ...notification,
        notificationType: 'info',
        notificationText: t('user_updated'),
        notificationShow: true,
      });
    } else {
      setNotification({
        ...notification,
        notificationType: 'warning',
        notificationText: t('user_updated_error'),
        notificationShow: true,
      });
    }
  };

  return (
    <>
      {progressProperties.waitForProcess ? (
        <div>
          <Alert
            color={notification.notificationType}
            isOpen={notification.notificationShow}
            toggle={() => {
              setNotification({
                ...notification,
                notificationShow: false,
              });
            }}>
            <div
              style={{
                textAlign: 'center',
              }}>
              {notification.notificationText}
            </div>
          </Alert>

          <Form role="form" onSubmit={handleSubmit}>
            <FormGroup className="mb-3">
              <InputGroup className="input-group-alternative">
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <BsFillPersonFill style={{ fontSize: '1.1rem' }} />
                  </InputGroupText>
                </InputGroupAddon>
                <Input
                  placeholder={t('name_place_holder')}
                  type="text"
                  name="firstName"
                  minLength="3"
                  maxLength="20"
                  required="required"
                  onInvalid={handleInvalidInput}
                  onInput={handleInvalidInput}
                  value={user.firstName}
                  onChange={onChange}
                />
              </InputGroup>
            </FormGroup>
            <FormGroup className="mb-3">
              <InputGroup className="input-group-alternative">
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <BsFillPersonFill style={{ fontSize: '1.1rem' }} />
                  </InputGroupText>
                </InputGroupAddon>
                <Input
                  placeholder={t('surname_place_holder')}
                  type="text"
                  name="lastName"
                  minLength="3"
                  maxLength="20"
                  required="required"
                  onInput={handleInvalidInput}
                  onInvalid={handleInvalidInput}
                  value={user.lastName}
                  onChange={onChange}
                />
              </InputGroup>
            </FormGroup>
            <FormGroup className="mb-3">
              <InputGroup className="input-group-alternative">
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <AiTwotonePhone style={{ fontSize: '1.1rem' }} />
                  </InputGroupText>
                </InputGroupAddon>
                <Input
                  placeholder={t('phone_number_place_holder')}
                  type="phone"
                  name="phoneNumber"
                  value={user.phoneNumber}
                  onChange={onChange}
                />
              </InputGroup>
            </FormGroup>
            <FormGroup>
              <InputGroup className="input-group-alternative">
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <BsFillPersonFill
                      style={{ fontSize: '1.1rem' }}></BsFillPersonFill>
                  </InputGroupText>
                </InputGroupAddon>
                <Input
                  name="birthDate"
                  placeholder={t('birth_date_place_holder')}
                  type="date"
                  value={user.birthDate}
                  onChange={onChange}
                />
              </InputGroup>
            </FormGroup>
            <FormGroup className="mb-3">
              <InputGroup className="input-group-alternative">
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <i className="ni ni-email-83" />
                  </InputGroupText>
                </InputGroupAddon>
                <Input
                  placeholder={t('email_place_holder')}
                  type="email"
                  name="username"
                  disabled="disabled"
                  value={user.username}
                />
              </InputGroup>
            </FormGroup>
            <FormGroup row className="mb-3" tag="fieldset">
              <InputGroup
                className="input-group-alternative"
                style={{ marginLeft: '1rem', width: '50rem' }}>
                <FormGroup check>
                  <InputGroupText>
                    <Input
                      name="gender"
                      type="radio"
                      value={'FEMALE'}
                      checked={user.gender === 'FEMALE'}
                      onChange={onChange}
                    />
                    {t('gender_female')}
                  </InputGroupText>
                </FormGroup>
                <FormGroup check style={{ marginLeft: '1rem' }}>
                  <InputGroupText>
                    <Input
                      name="gender"
                      type="radio"
                      value={'MALE'}
                      checked={user.gender === 'MALE'}
                      onChange={onChange}
                    />
                    <Label check>{t('gender_male')}</Label>
                  </InputGroupText>
                </FormGroup>
              </InputGroup>
            </FormGroup>
            <Row className="my-4">
              <Col xs="12">
                <div className="text-right">
                  <Button className="my-4" color="primary" type="submit">
                    Kaydet
                  </Button>
                </div>
              </Col>
            </Row>
          </Form>
        </div>
      ) : (
        <LoadingForm />
      )}
    </>
  );
};

export default ProfileForm;
