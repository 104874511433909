import React from 'react';
import {Provider} from 'react-redux';
import {createRoot} from 'react-dom/client';
import {BrowserRouter} from 'react-router-dom';

import './assets/vendor/nucleo/css/nucleo.css';
import './assets/vendor/font-awesome/css/font-awesome.min.css';
import './assets/scss/argon-design-system-react.scss?v1.1.0';

import AppRoutes from './routing/Routes';
import {reduxStore} from 'src/state/ReduxStore';

const root = createRoot(
  document.getElementById('root') || document.createElement('div'),
);

root.render(
  <Provider store={reduxStore}>
    <BrowserRouter>
      <AppRoutes />
    </BrowserRouter>
  </Provider>,
);
