import React, {PropsWithChildren, useState} from 'react';
import {
  Alert,
  Card,
  CardBody,
  CardHeader,
  CardFooter,
  Col,
  Container,
  Form,
  Row,
  Spinner,
  ButtonGroup,
  Button,
} from 'reactstrap';
import {connect, ConnectedProps} from 'react-redux';
import {bindActionCreators} from '@reduxjs/toolkit';

import {useTranslation} from 'src/common/i18n/I18n';
import VerificationDocumentUploadForm from 'src/views/forms/VerificationDocumentUploadForm';
import OrganizationMailSendForm from 'src/views/forms/OrganizationMailSendForm';
import {
  documentUpload,
  organizationMailConfirm,
  setNotificationInfo, verificationBackToInitialState,
} from 'src/state/slices/verificationSlice';
import {IOrganizationMailConfirmDto} from 'types/verificationTypes';
import OrganizationMailConfirmForm from 'src/views/forms/OrganizationMailConfirmForm';
import VerificationStatusCheckForm from 'src/views/forms/VerificationStatusCheckForm';

const VerificationFormTabs: React.FC<IVerificationFormTabsProps> = (
  props: PropsWithChildren<IVerificationFormTabsProps>,
): JSX.Element => {
  const {
    verificationInfo,
    notificationInfo,
    showLoading,
    setNotificationInfo,
    organizationMailConfirm,
    verificationBackToInitialState,
    documentUpload,
  } = props;
  const {t} = useTranslation();
  const [activeTab, setActiveTab] = useState(1);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [organizationEmail, setOrganizationEmail] = useState('');
  const handleOrganizationMailSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.target);
    setOrganizationEmail(data.get('email') as string);
    const sendVerificationEmailDto: IOrganizationMailConfirmDto = {
      email: data.get('email') as string,
      verificationId: (verificationInfo?.id as string) || '',
    };
    organizationMailConfirm({
      organizationMailConfirmDto: sendVerificationEmailDto,
    });
  };

  const handleDocumentUploadSubmit = (event) => {
    event.preventDefault();
    const formData = new FormData();
    formData.append('verificationId', verificationInfo.id || '');
    formData.append('files', selectedFiles[0]);
    documentUpload({documentUploadDto: formData});
  };

  const handleBackToInitialState = (event) => {
    event.preventDefault();
    const formData = new FormData();
    formData.append('id', verificationInfo?.id || '');
    verificationBackToInitialState(formData);
  };

  return (
    <>
      <section className="section section-shaped section-lg">
        <div className="shape shape-style-1 bg-gradient-default">
          <span />
          <span />
          <span />
          <span />
          <span />
          <span />
          <span />
          <span />
        </div>
        <Container className="pt-lg-6">
          <Row className="justify-content-center">
            <Col lg="6" md="9" sm="12" xs="12">
              <Alert
                color={notificationInfo?.isOpenAlertBoxColor ?? null}
                isOpen={notificationInfo?.isOpenAlertBox ?? null }
                toggle={() => setNotificationInfo(null)}>
                {notificationInfo?.isOpenAlertBoxText ?? null}
              </Alert>

              <Card className="bg-secondary shadow border-0">
                <CardHeader className="bg-white">
                  <ButtonGroup>
                    <Button
                      color="primary"
                      outline
                      onClick={() => setActiveTab(1)}
                      active={activeTab === 1}>
                      {t('verificationFormTabs_mail_verification')}
                    </Button>
                    <Button
                      color="primary"
                      outline
                      onClick={() => setActiveTab(2)}
                      active={activeTab === 2}>
                      {t('verificationFormTabs_doc_verification')}
                    </Button>
                  </ButtonGroup>
                </CardHeader>
                <CardBody className="px-lg-6 py-lg-6">
                  <div
                    className="text-center text-muted mb-4"
                    style={{
                      display: showLoading ? 'block' : 'none',
                    }}>
                    <Spinner
                      style={{width: '4rem', height: '4rem'}}
                      type="grow"
                      color="warning"
                    />
                  </div>

                  {activeTab === 1 ? ((verificationInfo != null && verificationInfo.verificationByEmailList) && verificationInfo.verificationByEmailList.length>0 && verificationInfo.verificationByEmailList[0].state === 'MAIL_SENT' ? (
                      <OrganizationMailConfirmForm organizationEmail={organizationEmail} />
                  ) : <OrganizationMailSendForm
                      showLoading={showLoading}
                      handleOrganizationMailSubmit={
                        handleOrganizationMailSubmit
                      }
                  />) : ( (verificationInfo != null && verificationInfo.verificationByDocumentList) && verificationInfo.verificationByDocumentList.length>0 && verificationInfo.verificationByDocumentList[0].state === 'UPLOADED' ? (
                    <VerificationStatusCheckForm />
                  ) : (
                    <VerificationDocumentUploadForm
                        showLoading={showLoading}
                        verificationInfo={verificationInfo}
                        handleDocumentUploadSubmit={handleDocumentUploadSubmit}
                        setSelectedFiles={setSelectedFiles}
                    />
                  )) }
                </CardBody>
                { verificationInfo?.state === 'SUBMITTED' &&
                <CardFooter>
                  <Form role="form" onSubmit={handleBackToInitialState}>
                    <Row>
                      <Col xs="12">
                        <div className="text-left">
                          <Button
                              outline
                              color="secondary"
                              type="submit"
                              style={{width: '25%'}}>
                            Geri
                          </Button>
                        </div>
                      </Col>
                    </Row>
                  </Form>
                </CardFooter>
                }
              </Card>
            </Col>
          </Row>
        </Container>

        {/* SVG separator */}
        <div className="separator separator-bottom separator-skew zindex-100">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            preserveAspectRatio="none"
            version="1.1"
            viewBox="0 0 2560 100"
            x="0"
            y="0">
            <polygon className="fill-white" points="2560 0 2560 100 0 100" />
          </svg>
        </div>
      </section>
    </>
  );
};

const mapStateToProps = (state) => ({
  verificationInfo: state.verification.verificationInfo,
  showLoading: state.common.showLoading,
  notificationInfo: state.verification.notificationInfo,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {organizationMailConfirm, documentUpload, setNotificationInfo, verificationBackToInitialState},
    dispatch,
  );

type VerificationFormTabsProps = {
  toggleModal: () => void;
  setModalProperties: (event) => void;
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type IVerificationFormTabsProps = ConnectedProps<typeof connector> &
  VerificationFormTabsProps;

export default connector(VerificationFormTabs);
