import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import VerificationService from 'src/common/services/VerificationService';
import {
  IBrand,
  ICommunity,
  INotification,
  IOrganization,
  IOrganizationMailConfirmDto,
  IValidateVerificationEmailDto,
  IVerification,
  IVerificationFormDto,
} from 'src/types/verificationTypes';
import {getErrorMessage} from 'src/common/utils/Utils';
import {NotificationAlertColors} from 'src/common/constants/enums';
import Logger from 'src/common/utils/Logger';

export const verificationBackToInitialState = createAsyncThunk(
    'verification/verificationBackToInitialState',
    async (
        payload: FormData,
        // @ts-ignore
        {rejectWithValue},
    ) => {
      try {
        const response = await VerificationService.postBackToVerificationInitialState(
            payload,
        );
        const responseJson = await response.json();
        if (!response.ok) {
          return rejectWithValue(responseJson.message);
        } else if (response.status == 500) {
          return rejectWithValue(responseJson.message);
        } else {
          return responseJson;
        }
      } catch (e) {
        Logger.error(e);
        return rejectWithValue(e);
      }
    },
);

export const organizationMailConfirmSubmit = createAsyncThunk(
  'verification/organizationMailConfirmSubmit',
  async (
    payload: IValidateVerificationEmailDto,
    // @ts-ignore
    {rejectWithValue},
  ) => {
    try {
      const response = await VerificationService.postVerificationMailCode(
        payload,
      );
      const responseJson = await response.json();
      if (!response.ok) {
        return rejectWithValue(responseJson.message);
      } else if (response.status == 500) {
        return rejectWithValue(responseJson.message);
      } else {
        return responseJson;
      }
    } catch (e) {
      Logger.error(e);
      return rejectWithValue(e);
    }
  },
);

export const getVerificationInfo = createAsyncThunk(
  'verification/getVerificationInfo',
  async (
    payload: {verificationId: string},
    // @ts-ignore
    {rejectWithValue},
  ) => {
    try {
      const response = await VerificationService.getVerificationInfo(
        payload.verificationId,
      );
      const responseJson = await response.json();
      if (!response.ok) {
        return rejectWithValue(responseJson.message);
      } else if (response.status == 500) {
        return rejectWithValue(responseJson.message);
      } else {
        return responseJson;
      }
    } catch (e) {
      Logger.error(e);
      return rejectWithValue(e);
    }
  },
);

export const verificationFormSubmit = createAsyncThunk(
  'verification/verificationFormSubmit',
  // @ts-ignore
  async (
    payload: {verificationFormDto: IVerificationFormDto},
    {rejectWithValue},
  ) => {
    try {
      const response = await VerificationService.postVerificationForm(
        payload.verificationFormDto,
      );
      const responseJson = await response.json();
      if (!response.ok) {
        return rejectWithValue(responseJson.message);
      } else if (response.status == 500) {
        return rejectWithValue(responseJson.message);
      } else {
        return responseJson;
      }
    } catch (e) {
      Logger.error(e);
      return rejectWithValue(e);
    }
  },
);

export const organizationMailConfirm = createAsyncThunk(
  'verification/organizationMailConfirm',
  async (
    payload: {organizationMailConfirmDto: IOrganizationMailConfirmDto},
    {rejectWithValue},
  ) => {
    try {
      const response = await VerificationService.postVerificationMail(
        payload.organizationMailConfirmDto,
      );
      const responseJson = await response.json();
      if (!response.ok) {
        return rejectWithValue(responseJson.message);
      } else if (response.status == 500) {
        return rejectWithValue(responseJson.message);
      } else {
        return responseJson;
      }
    } catch (e) {
      Logger.error(e);
      return rejectWithValue(e);
    }
  },
);

export const documentUpload = createAsyncThunk(
  'verification/documentUpload',
  async (payload: {documentUploadDto: FormData}, {rejectWithValue}) => {
    try {
      const response = await VerificationService.postVerificationDocuments(
        payload.documentUploadDto,
      );
      const responseJson = await response.json();
      if (!response.ok) {
        return rejectWithValue(responseJson.message);
      } else if (response.status == 500) {
        return rejectWithValue(responseJson.message);
      } else {
        return responseJson;
      }
    } catch (e) {
      Logger.error(e);
      return rejectWithValue(e);
    }
  },
);

const INITIAL_VERIFICATION_STATE = {} as IVerification;
const INITIAL_ORGANIZATION_STATE = [] as IOrganization[];
const INITIAL_BRAND_STATE = {} as IBrand;
const INITIAL_COMMUNITY_STATE = {} as ICommunity;
const INITIAL_NOTIFICATION_STATE = null as null | INotification;

const verificationSlice = createSlice({
  name: 'verification',
  initialState: {
    verificationByEmailList: null,
    verificationByDocumentList: null,
    verificationInfo: INITIAL_VERIFICATION_STATE,
    brand: INITIAL_BRAND_STATE,
    community: INITIAL_COMMUNITY_STATE,
    organizationList: INITIAL_ORGANIZATION_STATE,
    returnUrl: '',
    error: null,
    notificationInfo: INITIAL_NOTIFICATION_STATE,
  },
  reducers: {
    setVerification: (state, action) => {
      state.verificationInfo = action.payload;
    },
    setVerificationError: (state, action) => {
      state.error = action.payload;
    },
    setNotificationInfo: (state, action) => {
      state.notificationInfo = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getVerificationInfo.fulfilled, (mutualState, action) => {
      const {
        brand,
        community,
        organizationList,
        returnUrl,
      } = action.payload;

      mutualState.verificationInfo = action.payload;
      mutualState.brand = brand;
      mutualState.community = community;
      mutualState.organizationList = organizationList;
      mutualState.returnUrl = returnUrl;
    });
    builder.addCase(getVerificationInfo.rejected, (state, action) => {
      state.notificationInfo = {} as INotification;
      state.notificationInfo.notification = true;
      state.notificationInfo.isOpenAlertBoxText = !action.payload
        ? 'Beklenmeyen bir hata ile karşılaşıldı...'
        : getErrorMessage(action.payload as string);
      state.notificationInfo.isOpenAlertBoxColor =
        NotificationAlertColors.Danger;
      state.notificationInfo.isOpenAlertBox = true;
    });
    builder.addCase(verificationBackToInitialState.fulfilled, (state, action) => {
      state.verificationInfo = action.payload;
    });
    builder.addCase(verificationBackToInitialState.rejected, (state, action) => {
      state.notificationInfo = {} as INotification;
      state.notificationInfo.notification = true;
      state.notificationInfo.isOpenAlertBoxText = !action.payload
          ? 'Beklenmeyen bir hata ile karşılaşıldı...'
          : getErrorMessage(action.payload as string);
      state.notificationInfo.isOpenAlertBoxColor =
          NotificationAlertColors.Danger;
      state.notificationInfo.isOpenAlertBox = true;
    });
    builder.addCase(
      organizationMailConfirmSubmit.fulfilled,
      (state, action) => {
        state.verificationInfo = action.payload as unknown as IVerification;
        state.notificationInfo = {} as INotification;
        state.notificationInfo.notification = true;
        state.notificationInfo.isOpenAlertBoxText =
          'Doğruma kodunuz gönderildi';
        state.notificationInfo.isOpenAlertBoxColor =
          NotificationAlertColors.Info;
        state.notificationInfo.isOpenAlertBox = true;
      },
    );
    builder.addCase(organizationMailConfirmSubmit.rejected, (state, action) => {
      state.notificationInfo = {} as INotification;
      state.notificationInfo.notification = true;
      state.notificationInfo.isOpenAlertBoxText = !action.payload
        ? 'Beklenmeyen bir hata ile karşılaşıldı...'
        : getErrorMessage(action.payload as string);
      state.notificationInfo.isOpenAlertBoxColor =
        NotificationAlertColors.Danger;
      state.notificationInfo.isOpenAlertBox = true;
    });
    builder.addCase(verificationFormSubmit.fulfilled, (state, action) => {
      state.verificationInfo = action.payload;
      state.notificationInfo = null;
    });
    builder.addCase(verificationFormSubmit.rejected, (state, action) => {
      state.notificationInfo = {} as INotification;
      state.notificationInfo.notification = true;
      state.notificationInfo.isOpenAlertBoxText = !action.payload
        ? 'Beklenmeyen bir hata ile karşılaşıldı...'
        : getErrorMessage(action.payload as string);
      state.notificationInfo.isOpenAlertBoxColor =
        NotificationAlertColors.Danger;
      state.notificationInfo.isOpenAlertBox = true;
    });
    builder.addCase(organizationMailConfirm.fulfilled, (state, action) => {
      // @ts-ignore
      state.verificationInfo = action.payload;
      state.notificationInfo = {} as INotification;
      state.notificationInfo.notification = true;
      state.notificationInfo.isOpenAlertBoxText =
        'Kaydınız başarılı şekilde alındı.';
      state.notificationInfo.isOpenAlertBoxColor = NotificationAlertColors.Info;
      state.notificationInfo.isOpenAlertBox = true;
    });
    builder.addCase(organizationMailConfirm.rejected, (state, action) => {
      state.notificationInfo = {} as INotification;
      state.notificationInfo.notification = true;
      state.notificationInfo.isOpenAlertBoxText = !action.payload
        ? 'Beklenmeyen bir hata ile karşılaşıldı...'
        : getErrorMessage(action.payload as string);
      state.notificationInfo.isOpenAlertBoxColor =
        NotificationAlertColors.Danger;
      state.notificationInfo.isOpenAlertBox = true;
    });
    builder.addCase(documentUpload.fulfilled, (state, action) => {
      // @ts-ignore
      state.verificationInfo = action.payload;
      state.notificationInfo = {} as INotification;
      state.notificationInfo.notification = true;
      state.notificationInfo.isOpenAlertBoxText =
        'Kaydınız başarılı şekilde alındı.';
      state.notificationInfo.isOpenAlertBoxColor = NotificationAlertColors.Info;
      state.notificationInfo.isOpenAlertBox = true;
    });
    builder.addCase(documentUpload.rejected, (state, action) => {
      state.notificationInfo = {} as INotification;
      state.notificationInfo.notification = true;
      state.notificationInfo.isOpenAlertBoxText = !action.payload
        ? 'Beklenmeyen bir hata ile karşılaşıldı...'
        : getErrorMessage(action.payload as string);
      state.notificationInfo.isOpenAlertBoxColor =
        NotificationAlertColors.Danger;
      state.notificationInfo.isOpenAlertBox = true;
    });
  },
});

export const {reducer, actions} = verificationSlice;
export const {setVerification, setVerificationError, setNotificationInfo} =
  actions;
export default {reducer};
