const API_URL = '/api/auth';

/**
 * Auth Service
 */
class AuthService {
  /**
   *
   * @param {JSON} userdto
   * @return {Promise<Response>}
   */
  register(userdto: any): Promise<Response> {
    return fetch(API_URL + '/register', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(userdto),
    });
  }

  /**
   *
   * @param {string} username
   * @param {string} password
   * @return {Promise<Response>}
   */
  login(username: string, password: string): Promise<Response> {
    const userdto = {
      username: username,
      password: password,
    };

    return fetch(API_URL + '/login', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(userdto),
    })
      .then((response: Response | any) => {
        if (!response.ok) {
          throw new Error(response.status);
          // return response;
        } else {
          return response.json();
        }
      })
      .then((responseJson) => {
        // save JWT to local storage & redirect user from login page
        localStorage.setItem('user', JSON.stringify(responseJson));
        localStorage.setItem(
          'refreshToken',
          JSON.stringify(responseJson.refreshToken),
        );
        return responseJson;
      });
  }

  /**
   * Logout function
   */
  logout(): void {
    localStorage.removeItem('user');
  }

  /**
   *
   * @param {JSON} username
   * @return {Promise<Response>}
   */
  forgotPassword(username: string): Promise<Response> {
    return fetch(API_URL + '/forgotPassword', {
      method: 'POST',
      body: JSON.stringify({ username }),
      headers: { 'Content-Type': 'application/json' },
    });
  }

  /**
   *
   * @param {JSON} resetPasswordDTO
   * @return {Promise<Response>}
   */
  resetPassword(resetPasswordDTO: {
    password: string;
    confirmPassword: string;
    resetPasswordToken: string;
  }): Promise<Response> {
    return fetch(API_URL + '/resetPassword', {
      method: 'POST',
      body: JSON.stringify(resetPasswordDTO),
      headers: { 'Content-Type': 'application/json' },
    });
  }

  /**
   *
   * @return {any}
   */
  getCurrentUserToken(): string | null {
    const userData = localStorage.getItem('user');
    if (typeof userData === 'string') {
      return JSON.parse(userData).token;
    }
    return null;
  }

  /**
   *
   */
  removeCurrentUserToken() {
    localStorage.removeItem('user');
  }

  /**
   *
   */

  setUserToken(user: { token: string; username: string }) {
    localStorage.setItem('user', JSON.stringify(user));
  }

  /**
   *
   * @param {JSON} mailcodedto
   * @return {Promise<Response>}
   */
  validateMailCode(mailcodedto: any): Promise<Response> {
    return fetch(API_URL + '/email/validate', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(mailcodedto),
    });
  }

  /**
   *
   * @param {JSON} resendValidationCodeDto
   * @return {Promise<Response>}
   */
  resendValidateMailCode(resendValidationCodeDto: any): Promise<Response> {
    return fetch(API_URL + '/email/resend', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(resendValidationCodeDto),
    }).then((response: Response | any) => {
      if (!response.ok) {
        throw new Error(response.status);
      } else {
        return response.ok;
      }
    });
  }
}

export default new AuthService();
