import React, { useEffect, useState } from 'react';

// reactstrap components
import { Button, Card, Container, Row, Col } from 'reactstrap';

import AuthService from '../../common/services/AuthService';
import Login from './Login';
import ProfileService from 'src/common/services/ProfileService';

const Profile1: React.FC = (): JSX.Element => {
  const [token, setToken] = useState<string | null>(
    AuthService.getCurrentUserToken(),
  );
  const [user, updateUser] = useState({
    username: '',
  });

  const logout = (): void => {
    AuthService.logout();
    setToken(null);
  };

  // Fetch user from service & display on screen
  const fetchUserData = () => {
    ProfileService.getCurrentUser().then((response) => {
      // @ts-ignore
      updateUser(response);
    });
  };

  useEffect(() => {
    fetchUserData();
  }, []);

  // Check user has token
  if (!token) {
    return <Login />;
  }

  return (
    <>
      <main className="profile-page">
        <section className="section-profile-cover section-shaped my-0">
          {/* Circles background */}
          <div className="shape shape-style-1 shape-default alpha-4">
            <span />
            <span />
            <span />
            <span />
            <span />
            <span />
            <span />
          </div>
          {/* SVG separator */}
          <div className="separator separator-bottom separator-skew">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              preserveAspectRatio="none"
              version="1.1"
              viewBox="0 0 2560 100"
              x="0"
              y="0">
              <polygon className="fill-white" points="2560 0 2560 100 0 100" />
            </svg>
          </div>
        </section>
        <section className="section">
          <Container>
            <Card className="card-profile shadow mt--500">
              <div className="px-4">
                <Row className="justify-content-center">
                  <Col
                    className="order-lg-3 text-lg-right align-self-lg-center"
                    lg="4"
                  />
                  <Col
                    className="order-lg-3 text-lg-right align-self-lg-center"
                    lg="4"
                  />
                  <Col
                    className="order-lg-3 text-lg-right align-self-lg-center"
                    lg="4">
                    <div className="card-profile-actions py-4 mt-lg-0">
                      <Button
                        className="float-right"
                        color="default"
                        onClick={logout}
                        size="sm">
                        Çıkış
                      </Button>
                    </div>
                  </Col>
                </Row>
                <div className="text-center mt-5">
                  <h3>Miray KARACA </h3>
                  <div className="h6 font-weight-300">
                    <i className="ni location_pin mr-2" />
                    {/* user.username */}
                    {user.username}
                  </div>
                  <div className="h6 mt-4">
                    <i className="ni business_briefcase-24 mr-2" />
                    Bilkent Üniversitesi - Öğrenci
                  </div>
                </div>
                <div className="mt-5 py-5 border-top text-center">
                  <Row className="justify-content-center">
                    <Col lg="9">
                      <p>İndirim kuponunuz bulunmamaktadır.</p>
                      <a href="#pablo" onClick={(e) => e.preventDefault()}>
                        Daha Fazla İndirimler İçin
                      </a>
                    </Col>
                  </Row>
                </div>
              </div>
            </Card>
          </Container>
        </section>
      </main>
    </>
  );
};

export default Profile1;
