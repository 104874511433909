import React from 'react';
import { BsFillPersonCheckFill } from 'react-icons/bs';
import { RiGovernmentLine } from 'react-icons/ri';
import { BiMailSend } from 'react-icons/bi';
import { MdPhotoCamera, MdVerifiedUser } from 'react-icons/md';
import { TbFreeRights } from 'react-icons/tb';

// reactstrap components
import { Badge, Card, CardBody, Container, Row, Col } from 'reactstrap';

const Community: React.FC = (): JSX.Element => {
  return (
    <>
      {/* shape Hero */}
      <section className="section section-xl section-shaped pb-250">
        <div className="shape shape-style-1 shape-default">
          <span />
          <span />
          <span />
          <span />
          <span />
          <span />
          <span />
          <span />
          <span />
        </div>

        <Container className="py-lg-md d-flex">
          <div className="col px-0">
            <Row>
              <Col lg="8">
                <h1 className="display-3 text-white">
                  Kimliğinizi, mailinizi ve hesaplarınızın{' '}
                  <span>doğruluğunu teyit edelim.</span>
                </h1>

                <p className="lead text-white">
                  Firmaların sizler için sunmuş olduğu indirimlerden
                  yararlanabilmeniz için tek yapmanız gereken hesabınızı YETKY
                  ile doğrulamak.
                </p>
              </Col>
            </Row>
          </div>
        </Container>

        {/* SVG separator */}
        <div className="separator separator-bottom separator-skew">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            preserveAspectRatio="none"
            version="1.1"
            viewBox="0 0 2560 100"
            x="0"
            y="0">
            <polygon className="fill-white" points="2560 0 2560 100 0 100" />
          </svg>
        </div>
      </section>
      {/* 1st Hero Variation */}

      <section className="section section-lg pt-lg-0 mt--200">
        <Container></Container>
      </section>
      <section className="section section-lg">
        <Container>
          <Row className="row-grid align-items-center">
            <Col className="order-md-2" md="6">
              <img
                alt="..."
                className="img-fluid floating"
                src={require('src/assets/img/theme/promo-1.png')}
              />
            </Col>
            <Col className="order-md-1" md="6">
              <div className="pr-md-5">
                <div
                  className="icon icon-lg icon-shape
                            icon-shape-success shadow rounded-circle mb-5">
                  <BsFillPersonCheckFill /* style={{fontSize: '45', marginTop: '.5rem'}} */
                  />
                </div>
                <h3>Tek Doğrulama</h3>
                <p>
                  Farklı uygulamalar kullanmadan, tek bir uygulama üzerinden
                  herkes için geçerli doğrulama yapıyoruz.
                </p>

                <p>
                  Her mağaza için ayrı ayrı kimlik vermenize ya da farklı
                  uygulamar yüklemeye son veriyoruz. Kimliğinizi bir kere
                  tanımlıyorsunuz. Size özel oluşturuğumuz kodu kullanarak
                  indirimlerden yararlanabiliyorsunuz.
                </p>
                <ul className="list-unstyled mt-5">
                  <li className="py-2">
                    <div className="d-flex align-items-center">
                      <div>
                        <Badge className="badge-circle mr-3" color="success">
                          <RiGovernmentLine />
                        </Badge>
                      </div>
                      <div>
                        <h6 className="mb-0">
                          Kurumunuzun / Üniversitenizin Kimlik Sağlayıcıları
                        </h6>
                      </div>
                    </div>
                  </li>
                  <li className="py-2">
                    <div className="d-flex align-items-center">
                      <div>
                        <Badge className="badge-circle mr-3" color="success">
                          <BiMailSend />
                        </Badge>
                      </div>
                      <div>
                        <h6 className="mb-0">Kurumsal Mailiniz Üzerinden</h6>
                      </div>
                    </div>
                  </li>
                  <li className="py-2">
                    <div className="d-flex align-items-center">
                      <div>
                        <Badge className="badge-circle mr-3" color="success">
                          <MdPhotoCamera />
                        </Badge>
                      </div>
                      <div>
                        <h6 className="mb-0">Kimlik & Fotoğraf İle</h6>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <section className="section pb-0 bg-gradient-warning">
        <Container>
          <Row className="row-grid align-items-center">
            <Col className="order-lg-1" lg="6">
              <div className="d-flex px-3">
                <div>
                  <div
                    className="icon icon-lg icon-shape bg-gradient-white
                              shadow rounded-circle text-primary">
                    <TbFreeRights />
                  </div>
                </div>
                <div className="pl-4">
                  <h4 className="display-3 text-white">Ücretsiz</h4>
                  <p className="text-white">
                    Hangi topluluğa dahil olduğunuzu siz söyleyin indirimi biz
                    sağlayalım. İndirimlerden yararlanmak ücretsizdir.
                  </p>
                </div>
              </div>
              <Card className="shadow shadow-lg--hover mt-5">
                <CardBody>
                  <div className="d-flex px-3">
                    <div>
                      <div
                        className="icon icon-shape bg-gradient-success
                                    rounded-circle text-white">
                        <MdVerifiedUser />
                      </div>
                    </div>
                    <div className="pl-4">
                      <h5 className="title text-success">
                        Kimliksiz Doğrulama
                      </h5>
                      <p>
                        Öğrencilerden kimlik almadan doğrulama sağlıyoruz. Eğer
                        üniversitenizin kimlik sağlayıcısı yoksa mail adresiniz
                        üzerinden doğruluyoruz.
                      </p>
                    </div>
                  </div>
                </CardBody>
              </Card>
              <Card className="shadow shadow-lg--hover mt-5">
                <CardBody>
                  <div className="d-flex px-3">
                    <div>
                      <div
                        className="icon icon-shape bg-gradient-warning
                                rounded-circle text-white">
                        <i className="ni ni-active-40" />
                      </div>
                    </div>
                    <div className="pl-4">
                      <h5 className="title text-warning">Hızlı & Kolay</h5>
                      <p>
                        Her indirim için ayrı ayrı kimlik vermenize artık gerek
                        yok. Kimliğiniz teyit edildikten sonra 1 sene boyunca
                        yetkinlendirildiğiniz topluluğun indirimlerini
                        kullanabilirsiniz.
                      </p>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
        {/* SVG separator */}
        <div className="separator separator-bottom separator-skew zindex-100">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            preserveAspectRatio="none"
            version="1.1"
            viewBox="0 0 2560 100"
            x="0"
            y="0">
            <polygon className="fill-white" points="2560 0 2560 100 0 100" />
          </svg>
        </div>
      </section>

      <section className="section section-lg pt-0"></section>
    </>
  );
};

export default Community;
