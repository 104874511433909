import React from 'react';

import { Col, Container, Row, Button } from 'reactstrap';
import { MdNoEncryptionGmailerrorred } from 'react-icons/md';
import { AiFillHome } from 'react-icons/ai';

interface FailedVerificationProps {
  reason: string;
}

const FailedVerification: React.FC<FailedVerificationProps> = ({
  reason,
}: FailedVerificationProps): JSX.Element => {
  const reasons = {
    1: 'unknownStatus',
    2: 'noStatus',
  };

  const failedMessages = {
    1: 'Bilinmeyen bir durumdan dolayı kimlik doğrulamanız yapılamamıştır.',
    2: 'Böyle bir doğrulama bulunamamıştır.',
  };

  return (
    <>
      <section className="section" style={{ backgroundColor: '#5e72e421' }}>
        <Container>
          <Row
            className="row-grid align-items-center"
            style={{ marginTop: '3rem' }}>
            <Col
              md="12"
              style={{
                padding: '2rem',
                textAlign: 'center',
                color: '#fb6340',
                fontSize: '8rem',
              }}>
              <MdNoEncryptionGmailerrorred />
            </Col>
          </Row>
          <Row className="row-grid align-items-center">
            <Col
              md="12"
              style={{
                padding: '2rem',
                textAlign: 'center',
                fontSize: '8rem',
              }}>
              <h1 style={{ color: '#fb6340' }}>
                {reason === reasons['1'] && failedMessages['1']}
                {reason === reasons['2'] && failedMessages['2']}
              </h1>
            </Col>
          </Row>
          <Row className="row-grid align-items-center">
            <Col
              md="12"
              style={{
                padding: '2rem',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}>
              <Button
                style={{
                  color: '#ffffff',
                  backgroundColor: '#fb6340',
                  width: 'fit-content',
                  height: '4rem',
                  fontSize: '1.25rem',
                  padding: '0rem 2rem',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
                href="/">
                <AiFillHome
                  style={{
                    marginRight: '0.85rem',
                    marginBottom: '0.15rem',
                    fontSize: '1.25rem',
                  }}
                />
                Anasayfa
              </Button>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default FailedVerification;
