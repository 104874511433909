import React, { PropsWithChildren, useEffect } from 'react';
import { Link } from 'react-router-dom';
import {
  UncontrolledCollapse,
  UncontrolledTooltip,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Container,
  Row,
  Col,
} from 'reactstrap';

// JavaScript plugin that hides or shows a component based on your scroll
import Headroom from 'headroom.js';
import 'src/assets/css/yetky-theme.scss';
import { connect, ConnectedProps } from 'react-redux';
import { bindActionCreators } from '@reduxjs/toolkit';
import { logout } from 'src/state/slices/authSlice';

const MainNavbar: React.FC<NavigationBarProps> = (
  props: PropsWithChildren<NavigationBarProps>,
): JSX.Element => {
  const [collapseClasses, setCollapseClasses] = React.useState('');
  const { authData, logout } = props;

  useEffect(() => {
    const headroom = new Headroom(document.getElementById('navbar-main'));
    // initialise
    headroom.init();
  }, []);

  const onExiting = () => {
    setCollapseClasses('collapsing-out');
  };

  const onExited = () => {
    setCollapseClasses('');
  };

  const logoutHandler = () => {
    logout();
  };

  return (
    <>
      <header className="header-global">
        <Navbar
          className="navbar-main navbar-transparent navbar-light headroom"
          expand="lg"
          id="navbar-main">
          <Container>
            <NavbarBrand className="mr-lg-5 navbar-yetky" to="/" tag={Link}>
              <img
                alt="..."
                src={require('src/assets/img/brand/yetky_64_k.png')}
                style={{
                  float: 'left',
                  height: 60,
                  width: 60,
                }}
              />
              <h3
                className="text-white"
                style={{
                  float: 'left',
                  marginTop: 10,
                }}>
                YETKY
              </h3>
            </NavbarBrand>
            <button className="navbar-toggler" id="navbar_global">
              <span className="navbar-toggler-icon" />
            </button>
            <UncontrolledCollapse
              toggler="#navbar_global"
              navbar
              className={collapseClasses}
              onExiting={onExiting}
              onExited={onExited}>
              <div className="navbar-collapse-header">
                <Row>
                  <Col className="collapse-brand" xs="6">
                    <Link to="/">
                      <div>YETKY</div>
                    </Link>
                  </Col>
                  <Col className="collapse-close" xs="6">
                    <button className="navbar-toggler" id="navbar_global">
                      <span />
                      <span />
                    </button>
                  </Col>
                </Row>
              </div>

              <Nav
                className="align-items-lg-center ml-lg-auto"
                navbar
                style={{ display: authData?.isLoggedin ? 'none' : '' }}>
                <NavItem>
                  <NavLink
                    className="nav-link-icon"
                    href="/giris"
                    id="tooltip_navbar_login">
                    <i className="fa fa-sign-in" />
                    <span className="nav-link-inner--text d-lg-none ml-2">
                      Giriş
                    </span>
                  </NavLink>
                  <UncontrolledTooltip delay={0} target="tooltip_navbar_login">
                    Giriş için tıklayınız
                  </UncontrolledTooltip>
                </NavItem>
                <NavItem>
                  <NavLink
                    className="nav-link-icon"
                    href="/kayit"
                    id="tooltip_navbar_register">
                    <i className="fa fa-user-plus" />
                    <span className="nav-link-inner--text d-lg-none ml-2">
                      Kayıt
                    </span>
                  </NavLink>
                  <UncontrolledTooltip
                    delay={0}
                    target="tooltip_navbar_register">
                    Kayıt için tıklayınız
                  </UncontrolledTooltip>
                </NavItem>
              </Nav>

              <Nav
                className="align-items-lg-center ml-lg-auto"
                navbar
                style={{ display: authData?.isLoggedin ? '' : 'none' }}>
                <NavItem>
                  <NavLink
                    className="nav-link-icon"
                    href="/profil"
                    id="tooltip_navbar_profile">
                    <i className="fa fa-user" />
                    <span className="nav-link-inner--text d-lg-none ml-2">
                      Profil
                    </span>
                  </NavLink>
                  <UncontrolledTooltip
                    delay={0}
                    target="tooltip_navbar_profile">
                    Doğrulama için profile gidiniz
                  </UncontrolledTooltip>
                </NavItem>
                <NavItem>
                  <NavLink
                    className="nav-link-icon"
                    id="tooltip_navbar_logout"
                    onClick={logoutHandler}>
                    <i className="fa fa-sign-out" />
                    <span className="nav-link-inner--text d-lg-none ml-2">
                      Çıkış
                    </span>
                  </NavLink>
                  <UncontrolledTooltip delay={0} target="tooltip_navbar_logout">
                    Çıkış için tıklayınız
                  </UncontrolledTooltip>
                </NavItem>
              </Nav>
            </UncontrolledCollapse>
            {/* TODO: Dil desteği şimdilik kaldırıldı
                            <Languages />
                        */}
          </Container>
        </Navbar>
      </header>
    </>
  );
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ logout }, dispatch);

const mapStateToProps = (state) => {
  return {
    authData: state?.auth,
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type NavigationBarProps = ConnectedProps<typeof connector>;
export default connector(MainNavbar);
