import {
  Alert,
  Button,
  Col,
  Form,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Row,
} from 'reactstrap';
import React, {useEffect, useState} from 'react';
import {MdGroups} from 'react-icons/md';
import {handleInvalidInput} from 'src/common/utils/Utils';
import CommunityService from 'src/common/services/CommunityService';
import VerificationService from 'src/common/services/VerificationService';
import {translateCommunityName} from 'src/common/utils/Utils';
import {getSavedLanguage} from 'src/common/i18n/I18n';
import FormSubmitModal from 'src/components/Modals/FormSubmitModal';

interface Community {
  id: string;
  name: string;
  description: string;
}

interface Verification {
  id: string;
  state: string;
}

const ProfileCommunityVerificationForm: React.FC = (): JSX.Element => {
  // INITIALIZATION
  const [notification, setNotification] = useState({
    notificationText: '',
    notificationType: 'danger',
    notificationShow: false,
  });

  const [communityList, setCommunityList] = useState<Array<Community>>([]);
  const [verification, setVerification] = useState<Verification>({
    id: '',
    state: '',
  });

  // USE EFFECT
  useEffect(() => {
    fetchCommunityData();
  }, []);
  // Fetch user from service & display on screen
  const fetchCommunityData = async () => {
    const response: Response = await CommunityService.communityList();
    if (response.ok) {
      const data: Array<Community> = await response.json();
      if (getSavedLanguage !== 'en') {
        data.map((item) => {
          item.name = translateCommunityName(item.name);
        });
      }
      setCommunityList(data);
    } else {
      setNotification({
        ...notification,
        notificationText:
          'Doğrulama listesi çekilirken beklenmedik hata ile karşılaşıldı...',
        notificationType: 'danger',
        notificationShow: true,
      });
    }
  };

  const handleOptionOnChange = (event) => {
    event.preventDefault();
  };

  // SUBMIT REGISTRATION FORM
  const handleSubmit = async (event) => {
    event.preventDefault();
    const data = new FormData(event.target);
    const communityId = data.get('community')?.toString() || '';
    const createVerificationByUserDto = {
      communityId: communityId,
    };

    let response: Response =
      await VerificationService.getUserVerificationList();
    if (response.ok) {
      let data = await response.json();
      // check user has verification
      const verificationList = data.filter((verification) => {
        if (
          verification.community &&
          verification.community.id == communityId
        ) {
          return verification;
        }
      });

      // get verification id
      let verificationId = '';
      if (verificationList && verificationList.length == 0) {
        response = await VerificationService.getUserVerification(
          createVerificationByUserDto,
        );
        if (response.ok) {
          data = await response.json();
          verificationId = data.id;
          setVerification({
            id: verificationId,
            state: data.state,
          });
        }
      } else {
        verificationId = verificationList[0].id;
        setVerification({
          id: verificationId,
          state: verificationList[0].state,
        });
      }

      // get verification form
      if (verificationId === '') {
        setNotification({
          ...notification,
          notificationText:
            'Doğrulama formu oluşturulurken beklenmedik bir hata ile karşılaşıldı...',
          notificationType: 'danger',
          notificationShow: true,
        });
      }
    } else {
      setNotification({
        ...notification,
        notificationText:
          'Daha önce doğrulama talebiniz olduğundan yeniden oluşturamazsınız...',
        notificationType: 'warning',
        notificationShow: true,
      });
    }
  };

  /**
   * @desc Verification Form Component'ten gelen sonucu handle eder.
   * @param {Verification} verification
   */
  const handleVerificationChange = (verification) => {
    if (verification && verification.id && verification.id != '') {
      window.location.replace('/dogrulama/' + verification.id);
    }
  };

  if (verification && verification.id && verification.id != '') {
    if (verification.state === 'INITIAL') {
      window.location.replace('/dogrulama/' + verification.id);
    } else {
      handleVerificationChange(verification);
    }
  }

  const [modalProperties, setModalProperties] = useState({
    defaultModal: false,
    modalContent:
        'Gerekli adımlar tamamlandıktan sonra tekrar profil ' +
        ' sayfasına dönmeniz sağlanacaktır',
    modalTitle: 'Doğrulama İçin Yönlendirileceksiniz',
  });

  const toggleModal = () => {
    setModalProperties({
      ...modalProperties,
      defaultModal: !modalProperties.defaultModal,
    });
  };

  return (
    <>
      <FormSubmitModal
          formId={'profile-verification-form'}
          onModalToggle={toggleModal}
          isOpen={modalProperties.defaultModal}
          modalContent={modalProperties.modalContent}
          modalTitle={modalProperties.modalTitle}
      />

      <Alert
        color={notification.notificationType}
        isOpen={notification.notificationShow}
        toggle={() => {
          setNotification({
            ...notification,
            notificationShow: false,
          });
        }}>
        <div
          style={{
            textAlign: 'center',
          }}>
          {notification.notificationText}
        </div>
      </Alert>

      <Form role="form" id='profile-verification-form' onSubmit={handleSubmit}>
        <FormGroup className="mb-3">
          <InputGroup className="input-group-alternative">
            <InputGroupAddon addonType="prepend">
              <InputGroupText>
                <MdGroups style={{fontSize: '1.1rem'}} />
              </InputGroupText>
            </InputGroupAddon>
            <Input
              placeholder="Doğrulamak istediğiniz topluluğu seçiniz"
              type="select"
              name="community"
              onInvalid={handleInvalidInput}
              onInput={handleInvalidInput}
              onChange={handleOptionOnChange}>
              {communityList.map((option) => (
                <option key={option.id} value={option.id}>
                  {option.name}
                </option>
              ))}
            </Input>
          </InputGroup>
        </FormGroup>

        <Row className="my-4">
          <Col xs="12">
            <div className="text-right">
              <Button
                className="my-4"
                color="primary"
                onClick={() => toggleModal()}
                // type="submit"
                disabled={!communityList}>
                Doğrula
              </Button>
            </div>
          </Col>
        </Row>
      </Form>
    </>
  );
};

export default ProfileCommunityVerificationForm;
