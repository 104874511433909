export enum VerificationState {
  Initial = 'INITIAL',
  Submitted = 'SUBMITTED',
  Started = 'STARTED',
  Completed = 'COMPLETED',
  Error = 'ERROR',
}

export enum VerificationType {
  Email = 'MAIL',
  Doc = 'DOC',
  SSO = 'SSO',
}

export enum NotificationAlertColors {
  Info = 'info',
  Success = 'success',
  Warning = 'warning',
  Danger = 'danger',
}
