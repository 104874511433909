import React from 'react';
import { Button, Modal, Row, Col } from 'reactstrap';

interface ProfileVerificationStartModalProps {
  formId: string,
  onModalToggle: (modalParam) => void;
  isOpen: boolean;
  modalTitle: string;
  modalContent: string;
}

const FormSubmitModal: React.FC<ProfileVerificationStartModalProps> = (
  props: ProfileVerificationStartModalProps,
): JSX.Element => {
  const {
    formId,
    onModalToggle,
    isOpen,
    modalTitle,
    modalContent,
  } = props;

  return (
    <Modal
      className="modal-dialog-centered"
      isOpen={isOpen}
      toggle={onModalToggle}>
      <div className="modal-header">
        <h5 className="modal-title" id="exampleModalLabel">
          {modalTitle}
        </h5>
        <button
          aria-label="Close"
          className="close"
          data-dismiss="modal"
          type="button"
          onClick={onModalToggle}>
          <span aria-hidden={true}>×</span>
        </button>
      </div>

      <div className="modal-body">
        <p>{modalContent}</p>
      </div>

      <div className="modal-footer">
        <Row className="my-4">
          <Col xs="12">
            <div className="text-right">
              <Button
                className="ml-auto"
                color="primary"
                data-dismiss="modal"
                type="submit"
                form={formId}>
                Devam
              </Button>

              <Button
                className="ml-auto"
                color="link"
                data-dismiss="modal"
                type="button"
                onClick={onModalToggle}>
                Kapat
              </Button>
            </div>
          </Col>
        </Row>
      </div>

    </Modal>
  );
};

export default FormSubmitModal;
