import React from 'react';

// reactstrap components
import { Container, Row, Col } from 'reactstrap';

const About: React.FC = (): JSX.Element => {
  return (
    <>
      <section className="section section-hero section-shaped">
        {/* Background circles */}
        <div className="shape shape-style-1 shape-default">
          <span className="span-150" />
          <span className="span-50" />
          <span className="span-50" />
          <span className="span-75" />
          <span className="span-100" />
          <span className="span-75" />
          <span className="span-50" />
          <span className="span-100" />
          <span className="span-50" />
          <span className="span-100" />
        </div>
        <Container style={{ marginTop: '6rem' }}>
          <div className="col px-0">
            <Row className="align-items-center justify-content-center">
              <Col className="mb-lg-auto" lg="6">
                <img
                  alt="Sample"
                  src={require('src/assets/img/brand/about_5.png')}
                  style={{
                    padding: '2rem',
                    paddingBottom: '1rem',
                    height: '100%',
                    width: '100%',
                  }}
                />
              </Col>
              <Col
                className="mb-5 mb-lg-0"
                lg="5"
                style={{ textAlign: 'start' }}>
                <h1 className="text-white" style={{ fontWeight: 'bold' }}>
                  HAKKIMIZDA
                </h1>
                <p className="lead text-white mt-4">
                  Yetky, hem işletmeler hem de bireyler için geliştirilmiş
                  çevrimiçi kimlik doğrulama platformudur.
                </p>

                <p className="lead text-white mt-4">
                  İhtiyaca yönelik olarak yenilikçi kimlik doğrulama
                  çözümlerinde lider sağlayıcı olmayı hedefleyen bir girişimdir.
                </p>
              </Col>
            </Row>
          </div>
        </Container>
      </section>
      <section className="section section-lg">
        <Container>
          <Row className="row-grid justify-content-center">
            <Col className="text-center" lg="10">
              <p className="lead">
                Yetky kimlik doğrulamanın teknoloji çağındaki öneminin
                farkındalığı vizyonuyla, doğrulamanın önündeki tüm engellerin
                kaldırılmasını ve doğrulamanın en kolay şekilde yapılmasını
                hedefler.
              </p>
              <p className="lead">
                Platformumuz, hızlı ve güvenilir kimlik doğrulaması sağlamak
                üzere tasarlanmıştır ve işletmelerin kullanıcılarının kimliğini
                hızlı ve güvenli bir şekilde doğrulamasını kolaylaştırır.
              </p>
            </Col>
            <Col className="text-center" lg="10">
              <img
                alt="Sample"
                src={require('src/assets/img/brand/about_3.jpg')}
                style={{
                  padding: '2rem',
                  paddingBottom: '1rem',
                  height: '100%',
                  width: '100%',
                }}
              />
            </Col>
          </Row>
        </Container>
      </section>
      <section className="section section-hero section-shaped">
        {/* Background circles */}
        <div className="shape shape-style-1 shape-default">
          <span className="span-150" />
          <span className="span-50" />
          <span className="span-50" />
          <span className="span-75" />
          <span className="span-100" />
          <span className="span-75" />
          <span className="span-50" />
          <span className="span-100" />
          <span className="span-50" />
          <span className="span-100" />
        </div>
        <Container>
          <Row className="row-grid justify-content-center">
            <Col lg="10" style={{ textAlign: 'center' }}>
              <p className="lead text-white mt-4">
                Uzmanlardan oluşan ekibimiz, güçlü kimlik doğrulama çözümleri
                geliştirme konusunda uzun yıllara dayanan bir deneyime sahiptir
                ve bu alandaki teknolojik gelişmelerin ön saflarında yer almaya
                kararlıyız. Müşterilerimizin özel ihtiyaçlarına göre uyarlanmış
                çözümler sunarak, kimlik doğrulama ihtiyaçlarının
                karşılandığından emin olmak için ihtiyaç duydukları esnekliği
                onlara sağlamaktan gurur duyuyoruz.
              </p>
              <p className="lead text-white mt-4">
                İster müşterilerinizin kimliğini doğrulamak isteyen bir
                çevrimiçi satıcı, ister çevrimiçi olarak kimliğinizi doğrulamak
                isteyen bir kişi olun, platformumuzda hedeflerinize ulaşmak için
                ihtiyacınız olan araçlar bulunur. Hizmetlerimizi mevcut
                sistemlerinize entegre etmeyi kolaylaştıran, hem siz hem de
                kullanıcılarınız için orunsuz bir deneyim sağlayan bir dizi
                özellik sunuyoruz.
              </p>
              <p className="lead text-white mt-4">
                Yetky&apos;de kendimizi tüm müşterilerimize olağanüstü müşteri
                hizmetleri ve desteği sunmaya adadık. Benzersiz ihtiyaç ve
                gereksinimlerini anlamak için her bir müşterimizle yakın bir
                şekilde çalışıyoruz ve hizmetlerimizden en iyi şekilde
                yararlanmaları için ihtiyaç duydukları desteği ve rehberliği
                onlara sağlamaya kararlıyız.
              </p>
            </Col>
            <Col className="text-center" lg="10" style={{ textAlign: 'end' }}>
              <img
                alt="Sample"
                src={require('src/assets/img/brand/about_6.png')}
                style={{
                  padding: '2rem',
                  paddingBottom: '1rem',
                  height: '100%',
                  width: '100%',
                }}
              />
            </Col>
          </Row>
        </Container>
      </section>
      <section className="section">
        <Container className="shape-container d-flex align-items-center">
          <Row className="align-items-center justify-content-center">
            <Col className="mb-lg-auto" lg="6">
              <img
                alt="Sample"
                src={require('src/assets/img/brand/about_4.jpg')}
                style={{
                  padding: '2rem',
                  paddingBottom: '1rem',
                  height: '100%',
                  width: '100%',
                }}
              />
            </Col>
            <Col className="mb-5 mb-lg-0" lg="5" style={{ textAlign: 'end' }}>
              <p className="lead mt-4">
                Kimlik doğrulama sektörünün ön saflarında yer almaktan heyecan
                duyuyoruz ve hızlı, güvenli ve güvenilir çevrimiçi kimlik
                doğrulama çözümleri sağlamak için sizinle çalışmayı dört gözle
                bekliyoruz. Platformumuz hakkında daha fazla bilgi edinmek ve
                hedeflerinize ulaşmanıza nasıl yardımcı olabileceğimizi
                keşfetmek için bugün bize ulaşın.
              </p>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default About;
