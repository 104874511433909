export default {
  home_Hero_title:
    'Your new generation idendity verification service that digital and secury',
  home_Hero_description:
    'Instantly verify your customers for your offers for students, teachers, healthcare professionals and more.',
  howItWorks_title: 'Example Validation Flow',
  howItWorks_flow1: `Merchants supporting Yetky could request that a student validates its entitlement to the service or offer during the checkout process. The validation can be initiated either by seamlessly integrating a call to the API within the merchant's registration or payment process, or by using the Yetky 'I'm a Student' button. <br/><br/>The latter is shown in this example:`,
  howItWorks_flow2:
    'The student clicks on the button and is redirected to the Yetky website where they are asked to enter their email address. The email address is used to identify the student and to send a verification code to the student’s email address.',
  howItWorks_flow3:
    'The student enters the verification code and is redirected back to the merchant’s website. The merchant can now verify the student’s identity and grant access to the service or offer.',
  howItWorks_flow4: 'Users will be asked to login at their institution...',
  login_forgotPassword_button: 'Forgot Password ?',
  login_failed: 'Login failed. Please check your email and password.',
  login_top_title: 'Or sign in with credentials',
  login_remember_me: 'Remember me',
  login_button: 'Sign In',
  login_signup_button: 'Sign Up',
  forgotPassword_title: 'Forgot Password',
  forgotPassword_success_content:
    'A password reset link has been sent to your email address. Please check your email box.',
  forgotPassword_fail_content:
    'A password reset link could not be sent to your email address. Please check your email address and try again.',
  forgotPassword_email_validation_error: 'Please enter a valid email address.',
  forgotPassword_content:
    'Please enter your email address. A password reset link will be sent to your email address.',
  forgotPassword_email: 'Email',
  forgotPassword_button: 'Send Password Reset Link',
  resetPassword_title: 'Reset Password',
  resetPassword_new_password: 'New Password',
  resetPassword_confirm_password: 'Confirm Password',
  resetPassword_confirm_button: 'Reset Password',
  resetPassword_success_title: 'Reset Password',
  resetPassword_success_content:
    'Your password has been reset successfully. You can now login with your new password.',
  resetPassword_fail_content:
    'Your password could not be reset. Please try again.',
  resetPassword_passwords_not_match: 'Passwords do not match.',
  name_place_holder: 'Name',
  surname_place_holder: 'Surname',
  phone_number_place_holder: 'Your phone number',
  birth_date_place_holder: 'Your birth date',
  email_place_holder: 'Your personel email address',
  gender_female: 'Female',
  gender_male: 'Male',
  verify_identity_notification: 'Verify your identity',
  identity_fetch_error_notification:
    'Unexpected error encountered while fetching verified IDs',
  verify_identity_on_authentication_panel_notification:
    'You can verify your identities in the "Authentication" panel',
  qr_code_error_notification:
    'Unexpected error encountered while generating qr code',
  qr_code_button: 'Genarate Qr Code',
  user_updated: 'Your personel information has been updated.',
  user_updated_error: 'Error encountered while updating your information',
  verificationFormTabs_mail_verification: 'Verification with Email',
  verificationFormTabs_doc_verification: 'Verification with Document',
};
