import React, {
  PropsWithChildren,
  useCallback,
  useEffect,
  useState,
} from 'react';
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
  Alert,
} from 'reactstrap';
import { bindActionCreators } from '@reduxjs/toolkit';
import { connect, ConnectedProps } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';

import github from 'src/assets/img/icons/common/github.svg';
import google from 'src/assets/img/icons/common/google.svg';

import { handleInvalidInput } from 'src/common/utils/Utils';
import { useTranslation } from 'src/common/i18n/I18n';
import { login } from 'src/state/slices/authSlice';

import InfoModal from 'src/components/Modals/InfoModal';
import ForgotPasswordModal from 'src/components/Modals/ForgotPasswordModal';

const Login: React.FC<LoginProps> = (
  props: PropsWithChildren<LoginProps>,
): JSX.Element => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { login, error, isLoggedin } = props;
  const [searchParams] = useSearchParams();
  const token = searchParams.get('token');
  const [progressProperties, setProgressProperties] = useState({
    waitForProcess: false,
  });
  const [notification, setNotification] = useState(false);
  const [modalProperties, setModalProperties] = useState({
    defaultModal: false,
    modalContent: 'Şuan için google be github ile giriş yapılamamaktadır.',
    modalTitle: 'Google Girişi',
  });
  const [forgotPasswordModalOpen, setForgotPasswordModalOpen] = useState(false);

  useEffect(() => {
    setProgressProperties({
      waitForProcess: false,
    });
    if (error && error !== '') {
      setNotification(true);
    }

    if (isLoggedin) {
      setTimeout(() => {
        if (token) {
          navigate('/profil/sorgula/' + encodeURIComponent(token));
        } else {
          navigate('/profil');
        }
      }, 1000);
    }
  }, [error, isLoggedin]);

  const toggleModal = () => {
    setModalProperties({
      ...modalProperties,
      defaultModal: !modalProperties.defaultModal,
      // modalTitle: modalParam.modalTitle,
      // modalContent: modalParam.modalContent,
    });
  };

  const toggleForgotPasswordModal = useCallback(() => {
    setForgotPasswordModalOpen((prevState) => !prevState);
  }, []);

  const onDismiss = () => {
    setNotification(false);
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    const data = new FormData(event.target);
    const userdto = {
      username: data.get('email')?.toString() || '',
      password: data.get('password')?.toString() || '',
    };

    setProgressProperties({
      ...progressProperties,
      waitForProcess: true,
    });

    login(userdto);

    //  Başarısız giriş durumlarında buton disable kaldırılır
    if (error) {
      setProgressProperties({
        ...progressProperties,
        waitForProcess: false,
      });
    }
  };

  return (
    <>
      {/* Modal */}
      <InfoModal
        onModalToggle={toggleModal}
        isOpen={modalProperties.defaultModal}
        modalContent={modalProperties.modalContent}
        modalTitle={modalProperties.modalTitle}
      />

      <ForgotPasswordModal
        onModalToggle={toggleForgotPasswordModal}
        isOpen={forgotPasswordModalOpen}
      />

      <section className="section section-shaped section-lg">
        <div className="shape shape-style-1 bg-gradient-default">
          <span />
          <span />
          <span />
          <span />
          <span />
          <span />
          <span />
          <span />
        </div>
        <Container className="pt-lg-7">
          <Row className="justify-content-center">
            <Col lg="5">
              <div>
                <Alert color="danger" isOpen={notification} toggle={onDismiss}>
                  {t('login_failed')}
                </Alert>
              </div>

              <Card className="bg-secondary shadow border-0">
                <CardHeader
                  className="bg-white pb-5"
                  style={{ display: 'none' }}>
                  <div className="text-muted text-center mb-3">
                    <small>Sign in with</small>
                  </div>
                  <div className="btn-wrapper text-center">
                    <Button
                      className="btn-neutral btn-icon"
                      color="default"
                      href=""
                      // @ts-ignore
                      onClick={() => toggleModal()}>
                      <span className="btn-inner--icon mr-1">
                        <img alt="..." src={github} />
                      </span>
                      <span className="btn-inner--text">Github</span>
                    </Button>
                    <Button
                      className="btn-neutral btn-icon ml-1"
                      color="default"
                      href="#"
                      // @ts-ignore
                      onClick={() => toggleModal()}>
                      <span className="btn-inner--icon mr-1">
                        <img alt="..." src={google} />
                      </span>
                      <span className="btn-inner--text">Google</span>
                    </Button>
                  </div>
                </CardHeader>
                <CardBody className="px-lg-5 py-lg-5">
                  <div className="text-center text-muted mb-4">
                    <small>{t('login_top_title')}</small>
                  </div>
                  <Form role="form" onSubmit={handleSubmit}>
                    <FormGroup className="mb-3">
                      <InputGroup className="input-group-alternative">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="ni ni-email-83" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          placeholder="Email"
                          type="email"
                          name="email"
                          required="required"
                          onInput={handleInvalidInput}
                          onInvalid={handleInvalidInput}
                        />
                      </InputGroup>
                    </FormGroup>
                    <FormGroup>
                      <InputGroup className="input-group-alternative">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="ni ni-lock-circle-open" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          id="loginPasswordInputElement"
                          placeholder="Password"
                          type="password"
                          name="password"
                          autoComplete="off"
                          required="required"
                          onInput={handleInvalidInput}
                          onInvalid={handleInvalidInput}
                        />
                      </InputGroup>
                    </FormGroup>
                    <div
                      style={{ display: 'none' }}
                      className="custom-control
                                  custom-control-alternative custom-checkbox">
                      <input
                        className="custom-control-input"
                        id=" customCheckLogin"
                        type="checkbox"
                      />
                      <label
                        className="custom-control-label"
                        htmlFor=" customCheckLogin">
                        <span>{t('login_remember_me')}</span>
                      </label>
                    </div>
                    <div className="text-center">
                      <Button
                        className="my-4"
                        color="primary"
                        type="submit"
                        disabled={progressProperties.waitForProcess}>
                        {t('login_button')}
                      </Button>
                    </div>
                  </Form>
                </CardBody>
              </Card>
              <Row className="mt-3">
                <Col xs="6">
                  <a
                    className="text-light"
                    // @ts-ignore
                    onClick={() => setForgotPasswordModalOpen(true)}
                    href="#">
                    <small>{t('login_forgotPassword_button')}</small>
                  </a>
                </Col>
                <Col className="text-right" xs="6">
                  <a href={'/kayit'} className="text-light">
                    <small>{t('login_signup_button')}</small>
                  </a>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>

        {/* SVG separator */}
        <div className="separator separator-bottom separator-skew zindex-100">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            preserveAspectRatio="none"
            version="1.1"
            viewBox="0 0 2560 100"
            x="0"
            y="0">
            <polygon className="fill-white" points="2560 0 2560 100 0 100" />
          </svg>
        </div>
      </section>
    </>
  );
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ login }, dispatch);

const mapStateToProps = (state) => ({
  isLoggedin: state.auth.isLoggedin,
  error: state.auth.error,
  isVerificationRequested: state.auth.isVerificationRequested,
});

const connector = connect(mapStateToProps, mapDispatchToProps);

export type LoginProps = ConnectedProps<typeof connector>;
export default connector(Login);
