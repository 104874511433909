import {
  Alert,
  Button,
  Col,
  Form,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Row,
} from 'reactstrap';

import { handleInvalidInput } from '../../common/utils/Utils';
import React, { useState } from 'react';
import ProfileService from 'src/common/services/ProfileService';

type userPasswordChangeDto = {
  oldPassword: string;
  newPassword: string;
  confirmPassword: string;
};

const ProfilePasswordChangeForm: React.FC = (): JSX.Element => {
  // const {t} = useTranslation();
  // INITIALIZATION
  const [notification, setNotification] = useState({
    notificationText: '',
    notificationType: 'danger',
    notificationShow: false,
  });

  // SUBMIT USER PASSWORD CHANGE FORM
  const handleSubmit = async (event: any) => {
    event.preventDefault();
    const data = new FormData(event.target);
    const oldPassword = data.get('old_password')?.toString() || '';
    const newPass = data.get('new_password')?.toString() || '';
    const newPassconfirm = data.get('new_password_check')?.toString() || '';
    if (newPass !== newPassconfirm) {
      setNotification({
        ...notification,
        notificationText: 'Yeni girdiğiniz şifreleriniz aynı olmalıdır...',
        notificationType: 'warning',
        notificationShow: true,
      });
    } else {
      const userPasswordChangeDto: userPasswordChangeDto = {
        oldPassword: oldPassword,
        newPassword: newPass,
        confirmPassword: newPassconfirm,
      };

      const response: Response = await ProfileService.changePassword(
        userPasswordChangeDto,
      );
      if (response.ok) {
        setNotification({
          ...notification,
          notificationText: 'Şifreniz başarılı şekilde değiştirilmiştir...',
          notificationType: 'info',
          notificationShow: true,
        });
      } else {
        setNotification({
          ...notification,
          notificationText:
            'Şifrenizi değiştirirken hata ile karşılaşıldı... Lütfen daha sonra tekrar deneyiniz.',
          notificationType: 'warning',
          notificationShow: true,
        });
      }
    }
  };

  return (
    <>
      <Alert
        color={notification.notificationType}
        isOpen={notification.notificationShow}
        toggle={() => {
          setNotification({
            ...notification,
            notificationShow: false,
          });
        }}>
        <div
          style={{
            textAlign: 'center',
          }}>
          {notification.notificationText}
        </div>
      </Alert>

      <Form role="form" onSubmit={handleSubmit}>
        <FormGroup>
          <InputGroup className="input-group-alternative">
            <InputGroupAddon addonType="prepend">
              <InputGroupText>
                <i className="ni ni-lock-circle-open" />
              </InputGroupText>
            </InputGroupAddon>
            <Input
              placeholder="Eski Şifreniz"
              type="password"
              autoComplete="off"
              name="old_password"
              minLength="8"
              required
              onInput={handleInvalidInput}
              onInvalid={handleInvalidInput}
            />
          </InputGroup>
        </FormGroup>
        <FormGroup>
          <InputGroup className="input-group-alternative">
            <InputGroupAddon addonType="prepend">
              <InputGroupText>
                <i className="ni ni-lock-circle-open" />
              </InputGroupText>
            </InputGroupAddon>
            <Input
              placeholder="Yeni Şifreniz"
              type="password"
              autoComplete="off"
              name="new_password"
              minLength="8"
              required
              onInput={handleInvalidInput}
              onInvalid={handleInvalidInput}
            />
          </InputGroup>
        </FormGroup>
        <FormGroup>
          <InputGroup className="input-group-alternative">
            <InputGroupAddon addonType="prepend">
              <InputGroupText>
                <i className="ni ni-lock-circle-open" />
              </InputGroupText>
            </InputGroupAddon>
            <Input
              placeholder="Yeni Şifreniz Tekrar"
              type="password"
              autoComplete="off"
              name="new_password_check"
              minLength="8"
              required
              onInput={handleInvalidInput}
              onInvalid={handleInvalidInput}
            />
          </InputGroup>
        </FormGroup>
        <Row className="my-4">
          <Col xs="12">
            <div className="text-right">
              <Button className="my-4" color="primary" type="submit">
                Şifre Güncelle
              </Button>
            </div>
          </Col>
        </Row>
      </Form>
    </>
  );
};

export default ProfilePasswordChangeForm;
