import React from 'react';

import { Col, Container, Row } from 'reactstrap';

const Communication: React.FC = (): JSX.Element => {
  return (
    <>
      <section
        className="section"
        style={{ backgroundColor: '#FFFFFF !important' }}>
        <Container>
          <Row
            className="row-grid align-items-center"
            style={{ marginTop: '13rem' }}>
            <Col md="12" style={{ padding: '2rem' }}>
              <h1 className="text-blue">BİZE ULAŞIN</h1>
              <h2 className="lead">info@yetky.com</h2>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default Communication;
