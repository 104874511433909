import React, { useState } from 'react';

// reactstrap components
import { Card, CardBody, CardImg, Container, Row, Col } from 'reactstrap';

import InfoModal from 'src/components/Modals/InfoModal';

const Brand: React.FC = (): JSX.Element => {
  const [modalValues, setModalValues] = useState({
    defaultModal: false,
    modalContent: '',
    modalTitle: '',
  });

  const toggleModal = (modalParam: any) => {
    setModalValues({
      ...modalValues,
      defaultModal: !modalValues.defaultModal,
      modalContent: modalParam.myContent,
      modalTitle: modalParam.myTitle,
    });
  };

  return (
    <>
      <div className="position-relative">
        {/* Hero for FREE version */}
        <section className="section section-shaped">
          {/* Background circles */}
          <div className="shape shape-style-1 shape-default">
            <span className="span-150" />
            <span className="span-50" />
            <span className="span-50" />
            <span className="span-75" />
            <span className="span-100" />
            <span className="span-75" />
            <span className="span-50" />
            <span className="span-100" />
            <span className="span-50" />
            <span className="span-100" />
          </div>
          <Container className="shape-container d-flex align-items-center py-lg">
            <div className="col px-0">
              <Row className="align-items-center justify-content-center">
                <Col className="text-center" lg="10">
                  <Row>
                    <Col lg="12">
                      <h1 className="display-3 text-white">
                        Kimlik doğrulamayı <span>sizin yerinize yapalım.</span>
                      </h1>

                      <p className="lead text-white">
                        Kimlik doğrulama servisimizi kullanarak daha hızlı ve
                        güvenilir şekilde kullanıcılarınızın yetkinliklerini
                        öğrenebilirsiniz.
                      </p>

                      <p className="lead text-white">
                        Kullanıcı gruplarına özel indirimleri daha güvenlik
                        şekilde oluşturabilirsiniz.
                      </p>

                      {/*
                                                <div className="btn-wrapper">
                                                    <Button
                                                        className="btn-white btn-icon mb-3 mb-sm-0 ml-1"
                                                        color="default"
                                                        href=""
                                                        onClick={() => toggleModal({
                                                            myTitle: 'En yakında zamanda ' +
                                                                'kayıtlarımız başlayacaktır',
                                                            myContent: 'Kayıt olmak için sabırsızlandığınızın ' +
                                                                'farkındayız. Şuan için sizi servisimize ' +
                                                                'kaydedemediğimiz için çok üzgünüz.',
                                                        })}
                                                    >
                        <span className="btn-inner--icon mr-1">
                          <i className="ni ni-shop"/>
                        </span>
                                                        <span className="btn-inner--text">
                          Kayıt
                        </span>
                                                    </Button>
                                                    <Button
                                                        className="btn-white btn-icon mb-3 mb-sm-0 ml-1"
                                                        color="default"
                                                        href=""
                                                        onClick={() => toggleModal({
                                                            myTitle: 'En yakında zamanda ' +
                                                                'kayıtlarımız başlayacaktır',
                                                            myContent: 'Kayıt olmak için sabırsızlandığınızın ' +
                                                                'farkındayız. Şuan için sizi servisimize ' +
                                                                'kaydedemediğimiz için çok üzgünüz.',
                                                        })}
                                                    >
                        <span className="btn-inner--icon mr-1">
                          <i className="ni ni-single-02"/>
                        </span>
                                                        <span className="btn-inner--text">
                          Giriş
                        </span>
                                                    </Button>
                                                </div>
                                                */}
                    </Col>

                    {/* <InfoModal />  */}
                    <InfoModal
                      onModalToggle={toggleModal}
                      isOpen={modalValues.defaultModal}
                      modalContent={modalValues.modalContent}
                      modalTitle={modalValues.modalTitle}
                    />
                  </Row>
                </Col>
              </Row>
            </div>
          </Container>
          {/* SVG separator */}
          <div className="separator separator-bottom separator-skew zindex-100">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              preserveAspectRatio="none"
              version="1.1"
              viewBox="0 0 2560 100"
              x="0"
              y="0">
              <polygon className="fill-white" points="2560 0 2560 100 0 100" />
            </svg>
          </div>
        </section>
      </div>

      {/*
                // TODO: Markalar daha sonra buraya eklenecek
                <Brands />
                */}

      <section className="section">
        <Container>
          <Row className="row-grid align-items-center">
            <Col md="5">
              <Card className="bg-default shadow border-0">
                <CardImg
                  alt="..."
                  src={require('src/assets/img/theme/id_cards.jpeg')}
                  top
                />
                <blockquote className="card-blockquote">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="svg-bg"
                    preserveAspectRatio="none"
                    viewBox="0 0 583 95">
                    <polygon
                      className="fill-default"
                      points="0,52 583,95 0,95"
                    />
                    <polygon
                      className="fill-default"
                      opacity=".2"
                      points="0,42 583,95 683,0 0,95"
                    />
                  </svg>
                  <h4 className="display-3 font-weight-bold text-white">
                    Kimliksiz Kimlik Doğrulama
                  </h4>
                  <p className="lead text-italic text-white">
                    Müşterilerinizden kimlik almadan doğrulayın
                  </p>
                </blockquote>
              </Card>
            </Col>
            <Col md="6">
              <div className="pl-md-5">
                <div
                  className="icon icon-lg icon-shape
                            icon-shape-warning shadow rounded-circle mb-5">
                  <i className="ni ni-settings" />
                </div>
                <h3>Hizmetlerimizin Avantajları</h3>
                <p className="lead">
                  Müşterilerinizden kimlik kartı, fotoğraf ve imza almadan sizin
                  yerinize güvenilir kaynaklardan müşterilerinizi teyit edelim.
                </p>
                <p>
                  Sizin yerinize hızlı, güvenli ve uygun fiyatlarla
                  kullanıcılarınızı doğrulayalım. Öğrenci, öğretmen veya sağlık
                  çalışanlarına özelleşmiş indirimler sağlayın.
                </p>
                <p></p>
                <a
                  className="font-weight-bold text-warning mt-5"
                  href="#"
                  onClick={(e) => e.preventDefault()}>
                  İlk 3 ay ya da 300 kullanıcıya kadar ücretsiz !!!
                </a>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="section pb-0 bg-gradient-warning">
        <Container>
          <Row className="row-grid align-items-center">
            <Col className="order-lg-1" lg="6">
              <div className="d-flex px-3">
                <div>
                  <div
                    className="icon icon-lg icon-shape bg-gradient-white
                              shadow rounded-circle text-primary">
                    <i className="ni ni-building text-primary" />
                  </div>
                </div>
                <div className="pl-4">
                  <h4 className="display-3 text-white">Ücretlendirme</h4>
                  <p className="text-white">
                    Ücret politikamız gereği kullanım miktarına göre
                    fiyatlandırılmıştır.
                  </p>
                </div>
              </div>
              <Card className="shadow shadow-lg--hover mt-5">
                <CardBody>
                  <div className="d-flex px-3">
                    <div>
                      <div
                        className="icon icon-shape bg-gradient-success
                                  rounded-circle text-white">
                        <i className="ni ni-satisfied" />
                      </div>
                    </div>
                    <div className="pl-4">
                      <h5 className="title text-success">
                        Deneme Sürecinde Ücretsiz
                      </h5>
                      <p>
                        Servisimizin hızlı ve güvenilirliğini test edebilmeniz
                        için ilk 3 ay ya da 300 kullanıcıya kadar ücretsizdir.
                      </p>
                      <a
                        className="text-success"
                        href="#"
                        onClick={(e) => e.preventDefault()}>
                        Daha Fazla
                      </a>
                    </div>
                  </div>
                </CardBody>
              </Card>
              <Card className="shadow shadow-lg--hover mt-5">
                <CardBody>
                  <div className="d-flex px-3">
                    <div>
                      <div
                        className="icon icon-shape bg-gradient-warning
                                    rounded-circle text-white">
                        <i className="ni ni-active-40" />
                      </div>
                    </div>
                    <div className="pl-4">
                      <h5 className="title text-warning">
                        Doğrulama Başına Ücretlendirme
                      </h5>
                      <p>
                        Serivisimize üye olabilir ve deneme süresi boyunca
                        ücretsiz şekilde kullanabilirsiniz. Deneme süresi
                        sonrasında kullanıcı doğrulama başına 10 TL alınacaktır.
                      </p>
                      <a
                        className="text-warning"
                        href="#"
                        onClick={(e) => e.preventDefault()}>
                        Daha Fazla
                      </a>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
        {/* SVG separator */}
        <div className="separator separator-bottom separator-skew zindex-100">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            preserveAspectRatio="none"
            version="1.1"
            viewBox="0 0 2560 100"
            x="0"
            y="0">
            <polygon className="fill-white" points="2560 0 2560 100 0 100" />
          </svg>
        </div>
      </section>
    </>
  );
};

export default Brand;
