import React, { useState } from 'react';

import { useNavigate, useParams } from 'react-router-dom';
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
  Alert,
} from 'reactstrap';

import InfoModal from 'src/components/Modals/InfoModal';
import { handleInvalidInput } from 'src/common/utils/Utils';
import { useTranslation } from 'src/common/i18n/I18n';
import AuthService from 'src/common/services/AuthService';

type resetPasswordDTO = {
  password: string;
  confirmPassword: string;
  resetPasswordToken: string;
};

const ResetPassword: React.FC = (): JSX.Element => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { resetPasswordToken } = useParams<{ resetPasswordToken?: string }>();
  const [modalProperties, setModalProperties] = useState({
    defaultModal: false,
    modalContent: '',
    modalTitle: '',
  });
  const [notification, setNotification] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const [progressProperties, setProgressProperties] = useState({
    waitForProcess: false,
  });

  const toggleModal = () => {
    setModalProperties({
      ...modalProperties,
      defaultModal: !modalProperties.defaultModal,
      // modalTitle: modalParam.modalTitle,
      // modalContent: modalParam.modalContent,
    });
  };

  const onDismiss = () => {
    setNotification(false);
    setErrorMessage('');
  };

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    setProgressProperties({ waitForProcess: true });

    const data = new FormData(event.target);

    if (data.get('password') !== data.get('confirmPassword')) {
      setNotification(true);
      setErrorMessage(t('resetPassword_passwords_not_match'));
      setProgressProperties({ waitForProcess: false });
    } else {
      const resetPasswordDTO: resetPasswordDTO = {
        password: data.get('password')?.toString() || '',
        confirmPassword: data.get('confirmPassword')?.toString() || '',
        resetPasswordToken: resetPasswordToken || '',
      };
      const response: Response = await AuthService.resetPassword(
        resetPasswordDTO,
      );
      setProgressProperties({ waitForProcess: false });

      if (response.ok) {
        setModalProperties({
          defaultModal: true,
          modalTitle: t('resetPassword_success_title'),
          modalContent: t('resetPassword_success_content'),
        });

        setTimeout(() => {
          navigate('/giris');
        }, 3000);
      } else {
        setModalProperties({
          ...modalProperties,
          defaultModal: true,
          modalTitle: t('resetPassword_success_title'),
          modalContent: t('resetPassword_fail_content'),
        });
      }
    }
  };

  return (
    <>
      {/* Modal */}
      <InfoModal
        onModalToggle={toggleModal}
        isOpen={modalProperties.defaultModal}
        modalContent={modalProperties.modalContent}
        modalTitle={modalProperties.modalTitle}
      />

      <section className="section section-shaped section-lg">
        <div className="shape shape-style-1 bg-gradient-default">
          <span />
          <span />
          <span />
          <span />
          <span />
          <span />
          <span />
          <span />
        </div>
        <Container className="pt-lg-7">
          <Row className="justify-content-center">
            <Col lg="5">
              <div>
                <Alert color="danger" isOpen={notification} toggle={onDismiss}>
                  {errorMessage}
                </Alert>
              </div>

              <Card className="bg-secondary shadow border-0">
                <CardHeader>
                  <div className="text-muted text-center mb-3">
                    <legend>{t('resetPassword_title')}</legend>
                  </div>
                </CardHeader>
                <CardBody className="px-lg-5 py-lg-5">
                  <Form role="form" onSubmit={handleSubmit}>
                    <FormGroup className="mb-3">
                      <InputGroup className="input-group-alternative">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="ni ni-lock-circle-open" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          placeholder={t('resetPassword_new_password')}
                          type="password"
                          name="password"
                          required="required"
                          onInput={handleInvalidInput}
                          onInvalid={handleInvalidInput}
                        />
                      </InputGroup>
                    </FormGroup>
                    <FormGroup>
                      <InputGroup className="input-group-alternative">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="ni ni-lock-circle-open" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          placeholder={t('resetPassword_confirm_password')}
                          type="password"
                          name="confirmPassword"
                          autoComplete="off"
                          required="required"
                          onInput={handleInvalidInput}
                          onInvalid={handleInvalidInput}
                        />
                      </InputGroup>
                    </FormGroup>

                    <div className="text-center">
                      <Button
                        className="my-4"
                        color="primary"
                        type="submit"
                        disabled={progressProperties.waitForProcess}>
                        {t('resetPassword_confirm_button')}
                      </Button>
                    </div>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>

        {/* SVG separator */}
        <div className="separator separator-bottom separator-skew zindex-100">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            preserveAspectRatio="none"
            version="1.1"
            viewBox="0 0 2560 100"
            x="0"
            y="0">
            <polygon className="fill-white" points="2560 0 2560 100 0 100" />
          </svg>
        </div>
      </section>
    </>
  );
};

export default ResetPassword;
