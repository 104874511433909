const API_URL = '/api/community';

/**
 * Community Service
 */
class CommunityService {
  /**
   *
   * @param {JSON} resetPasswordDTO
   * @return {Promise<Response>}
   * @desc Topluluk listesini çeken servistir
   */
  communityList(): Promise<Response> {
    return fetch(API_URL + '/list', {
      method: 'GET',
    });
  }
}

export default new CommunityService();
