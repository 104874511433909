const API_URL = '/api/auth';

/**
 *
 * @return {{Authorization: *}|{}}
 */
export const authHeader = (): any => {
  const userData = localStorage.getItem('user');
  if (typeof userData === 'string') {
    const user = JSON.parse(userData);
    if (user && user.token) {
      return {
        Authorization: user.token,
      };
    } else {
      return null;
    }
  } else {
    return null;
  }
};

/**
 *
 * @return {{refreshToken: *}|{}}
 */
export const authRefreshToken = (): any => {
  const userData = localStorage.getItem('user');

  if (typeof userData === 'string') {
    const user = JSON.parse(userData);
    if (user && user.refreshToken) {
      return {
        refreshToken: user.refreshToken,
      };
    } else {
      return null;
    }
  } else {
    return null;
  }
};

/**
 * Bu fonsiyon ile kullanıcı JWT token refresh edilir
 *
 * @return {Promise<Response>}
 */
export const refreshJWTToken = (): Promise<Response> => {
  const header = authHeader();
  const refreshToken = authRefreshToken();
  if (header && refreshToken) {
    return fetch(API_URL + '/refreshToken', {
      method: 'POST',
      headers: {
        ...header,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        ...refreshToken,
      }),
    });
  } else {
    return new Promise<Response>(() => null);
  }
};

/**
 * @description
 *  Bu fonksiyon JWT token ile istek yapmak için oluşturulmuştur.
 *  Request header içine Authorization: Bearer {token} ekleyip isteği yapmaktadır.
 *  Eğer JWT token refresh edilmesi gerekiyorsa refresh edip tekrardan aynı isteği yollar
 *
 * @param {string} url
 * @param {JSON} options
 * @return {Promise<Response>}
 */
export const fetchWithAuth = async (url, options = {}) => {
  // @ts-ignore
  const header = authHeader();
  let optionsWithAuthorizationHeader = {
    ...options,
    headers: {
      // @ts-ignore
      ...options.headers,
      ...header,
    },
  };
  const response: Response = await fetch(url, optionsWithAuthorizationHeader);
  // Unauthorized (401) hatası ve JWT token süresi dolmuşsa
  if (response.status === 401) {
    const refreshToken = localStorage.getItem('refreshToken');
    if (refreshToken) {
      try {
        const refreshResponse = await refreshJWTToken();
        const refreshTokenData = await refreshResponse.json();
        const userData: string = localStorage.getItem('user') || '';
        const user = JSON.parse(userData);
        user.token = refreshTokenData.token;
        user.refreshToken = refreshTokenData.refreshToken;
        localStorage.setItem('user', JSON.stringify(user));
        localStorage.setItem('testtt', 'ssddd');
        localStorage.setItem(
          'refreshToken',
          JSON.stringify(refreshTokenData.refreshToken),
        );
        optionsWithAuthorizationHeader = {
          ...options,
          headers: {
            // @ts-ignore
            ...options.headers,
            ...authHeader(),
          },
        };
        return fetch(url, optionsWithAuthorizationHeader); // Orijinal isteği tekrar gönderin
      } catch (refreshError) {
        // Refresh token isteği başarısız oldu
        localStorage.removeItem('user'); // JWT tokeni ve refresh tokeni local storage'dan silin
        localStorage.removeItem('refreshToken');
        throw new Error('Token yenileme başarısız oldu... ');
      }
    } else {
      localStorage.removeItem('user');
      localStorage.removeItem('refreshToken');
      throw new Error('Refresh token bulunamadı');
    }
  }
  return response;
};
