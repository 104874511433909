import React, { useState } from 'react';

import { NavItem, NavLink, Nav, Container, Row, Col } from 'reactstrap';
import { useTranslation } from 'src/common/i18n/I18n';

import KVKKModal from 'src/components/Modals/KVKKModal';
import PrivacyPolicy from 'src/components/Modals/PrivacyPolicy';

const MainFooter: React.FC = (): JSX.Element => {
  const [privacyPolicy, setPrivacyPolicy] = useState(false);
  const { t } = useTranslation();
  const PrivacyPolicyToggle = () => {
    setPrivacyPolicy(!privacyPolicy);
  };
  const [kvkkModal, setKvkkModal] = useState(false);
  const KVKKToggle = () => setKvkkModal(!kvkkModal);

  return (
    <footer className="footer has-cards">
      <PrivacyPolicy isOpen={privacyPolicy} toggle={PrivacyPolicyToggle} />
      <KVKKModal isOpen={kvkkModal} toggle={KVKKToggle}></KVKKModal>
      <Container>
        <Row className="align-items-center justify-content-md-between yetky-xs-flex-wrap-reverse">
          <Col md="4" sm="3" xs="12">
            <div className="copyright">
              © {new Date().getFullYear()}{' '}
              <a href="" target="_blank">
                Yetky
              </a>
            </div>
          </Col>
          <Col md="8" sm="9" xs="12">
            <Nav className="nav-footer justify-content-end">
              <NavItem>
                <NavLink href="/nasil-calisir">{t('howItWorks_title')}</NavLink>
              </NavItem>
              <NavItem>
                <NavLink href="/hakkimizda">Hakkımızda</NavLink>
              </NavItem>
              <NavItem>
                <NavLink href="/iletisim">İletişim</NavLink>
              </NavItem>
              <NavItem>
                <NavLink href="#" onClick={PrivacyPolicyToggle} target="_blank">
                  Gizlilik Politikası
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink href="#" onClick={KVKKToggle} target="_blank">
                  KVKK
                </NavLink>
              </NavItem>
            </Nav>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default MainFooter;
