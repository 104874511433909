import React from 'react';

// reactstrap components
import { Container, Row, Col } from 'reactstrap';
import { useTranslation } from 'src/common/i18n/I18n';

const Hero: React.FC = (): JSX.Element => {
  const { t } = useTranslation();
  return (
    <div className="position-relative">
      {/* Hero for FREE version */}
      <section className="section section-hero section-shaped">
        {/* Background circles */}
        <div className="shape shape-style-1 shape-default">
          <span className="span-150" />
          <span className="span-50" />
          <span className="span-50" />
          <span className="span-75" />
          <span className="span-100" />
          <span className="span-75" />
          <span className="span-50" />
          <span className="span-100" />
          <span className="span-50" />
          <span className="span-100" />
        </div>
        <Container className="shape-container d-flex align-items-center py-lg">
          <div className="col px-0">
            <Row className="align-items-center justify-content-center">
              <Col className="text-center" lg="12">
                <h2 className="text-white">{t('home_Hero_title')}</h2>

                <p className="lead text-white">{t('home_Hero_description')}</p>
              </Col>
            </Row>
          </div>
        </Container>
        {/* SVG separator */}
      </section>
    </div>
  );
};

export default Hero;
