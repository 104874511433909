import React from 'react';
import { Row, Spinner } from 'reactstrap';

const LoadingForm: React.FC = (): JSX.Element => {
  return (
    <Row className="justify-content-center">
      <Spinner
        color="primary"
        className="yetky-card-cover-spinner"
        style={{
          height: '3rem',
          width: '3rem',
        }}
        type="grow"></Spinner>
    </Row>
  );
};

export default LoadingForm;
