import {configureStore} from '@reduxjs/toolkit';
import {reducer as authReducer} from 'src/state/slices/authSlice';
import {reducer as verificationReducer} from 'src/state/slices/verificationSlice';
import {reducer as commonReducer} from 'src/state/slices/commonSlice';
import promiseMiddleware from 'src/state/utils/promiseMiddleware';

const reduxStore = configureStore({
  reducer: {
    common: commonReducer,
    auth: authReducer,
    verification: verificationReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(promiseMiddleware),
});

export type RootState = ReturnType<typeof reduxStore.getState>;

export {reduxStore};
