import { fetchWithAuth } from 'src/common/services/RequestService';

const API_URL = '/api/auth';
/**
 * Profile Service
 */
class ProfileService {
  /**
   *
   * TODO: Bu fonsiyon getCurrentUser() yerine kullanılmalıdır.
   *
   * @return {Promise<Response>}
   */
  getUserInfo(): Promise<Response> {
    return fetchWithAuth(API_URL + '/currentUser', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    });
  }

  /**
   * Bu fonsiyon ile kullanıcı bilgilerinin servis üzerinden çekilmesi sağlanır
   *
   * @return {Promise<Response>}
   */
  getCurrentUser(): Promise<Response> {
    return fetchWithAuth(API_URL + '/currentUser', {
      method: 'GET',
    });
  }

  /**
   *
   * @param {JSON} userdto
   * @return {Promise<Response>}
   */
  updateUser(userdto: any): Promise<Response> {
    return fetchWithAuth(API_URL + '/updateUser', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(userdto),
    });
  }

  /**
   *
   * @param {JSON} changePasswordDTO
   * @return {Promise<Response>}
   * @desc Kullanıcıların eski şifrelerini alarak yeni şifreleri ile değiştirilmesini sağlarlar
   */
  changePassword(changePasswordDTO: {
    oldPassword: string;
    newPassword: string;
    confirmPassword: string;
  }): Promise<Response> {
    return fetchWithAuth(API_URL + '/changePassword', {
      method: 'POST',
      body: JSON.stringify(changePasswordDTO),
      headers: {
        'Content-Type': 'application/json',
      },
    });
  }
}

export default new ProfileService();
