import React from 'react';

// reactstrap components
import { Button, Container, Row, Col, UncontrolledCarousel } from 'reactstrap';

const items = [
  {
    src: require('src/assets/img/theme/form_work_flow_2.png'),
    altText: '',
    caption: '',
    header: '',
  },
  {
    src: require('src/assets/img/theme/form_work_flow_3.png'),
    altText: '',
    caption: '',
    header: '',
  },
  {
    src: require('src/assets/img/theme/form_work_flow_4.png'),
    altText: '',
    caption: '',
    header: '',
  },
  {
    src: require('src/assets/img/theme/form_work_flow_5.png'),
    altText: '',
    caption: '',
    header: '',
  },
];

const WorkFlowVerificationForm: React.FC = (): JSX.Element => {
  return (
    <section className="section section-shaped">
      <Container className="py-md">
        <Row className="justify-content-between align-items-center">
          <Col className="mb-5 mb-lg-0" lg="5">
            <h1 className="text-dark font-weight-bold">Hızlı & Basit</h1>
            <p className="lead text-dart mt-4">
              Sadece birkaç adımla kullanıcılarını hızlı ve basit bir şekilde
              doğrulayabilirsiniz.
            </p>
            <Button
              className="btn-white mt-4"
              color="default"
              href="/nasil-calisir">
              Nasıl Doğrulanıyor ?
            </Button>
          </Col>
          <Col className="mb-lg-auto" lg="6">
            <div className="rounded shadow-lg overflow-hidden transform-perspective-right">
              <UncontrolledCarousel items={items} />
            </div>
          </Col>
        </Row>
      </Container>
      {/* SVG separator */}
      <div className="separator separator-bottom separator-skew">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          preserveAspectRatio="none"
          version="1.1"
          viewBox="0 0 2560 100"
          x="0"
          y="0">
          <polygon className="fill-white" points="2560 0 2560 100 0 100" />
        </svg>
      </div>
    </section>
  );
};

export default WorkFlowVerificationForm;
