import React, {useState} from 'react';

import {
  Alert,
  Button,
  Col,
  Form,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Row,
} from 'reactstrap';

import {getErrorMessage, handleInvalidInput} from 'src/common/utils/Utils';
import AuthService from 'src/common/services/AuthService';
import {useNavigate} from 'react-router-dom';

interface RegisterFormProps {
  KVKKToggle: () => void;
  PrivacyPolicyToggle: () => void;
  waitForProcess: boolean;
}

const RegisterForm: React.FC<RegisterFormProps> = (
  prop: RegisterFormProps,
): JSX.Element => {
  // INITIALIZATION
  const [notification, setNotification] = useState({
    notificationText: '',
    notificationType: 'danger',
    notificationShow: false,
  });
  const navigate = useNavigate();
  const {KVKKToggle, PrivacyPolicyToggle, waitForProcess} = prop;

  // SUBMIT REGISTRATION FORM
  const handleSubmit = async (event) => {
    event.preventDefault();
    const data = new FormData(event.target);

    const userdto = {
      username: data.get('email'),
      password: data.get('password'),
      firstName: data.get('name'),
      lastName: data.get('surname'),
    };

    const email: any = userdto.username;
    const response: Response = await AuthService.register(userdto);
    if (response.ok) {
      setNotification({
        ...notification,
        notificationType: 'info',
        notificationText: 'Kaydınız başarılı şekilde alındı...',
        notificationShow: true,
      });
      localStorage.setItem('username', email);
      navigate('/kayit/dogrula');
    } else if (response?.status == 500) {
      const data = await response.json();
      if (data.message !== 'USER_ALREADY_EXIST') {
        localStorage.setItem('username', email);
        navigate('/kayit/dogrula');
      } else {
        const errorMsg = getErrorMessage(data.message);
        setNotification({
          ...notification,
          notificationType: 'danger',
          notificationText: errorMsg,
          notificationShow: true,
        });
      }
    } else {
      setNotification({
        ...notification,
        notificationType: 'danger',
        notificationText:
          'Kullanıcı kaydında beklenmeyen hata ile karşılaşıldı',
        notificationShow: true,
      });
    }
  };

  return (
    <>
      <Alert
        color={notification.notificationType}
        isOpen={notification.notificationShow}
        toggle={() => {
          setNotification({
            ...notification,
            notificationShow: false,
          });
        }}>
        {notification.notificationText}
      </Alert>

      <Form role="form" onSubmit={handleSubmit}>
        <FormGroup className="mb-3">
          <InputGroup className="input-group-alternative">
            <InputGroupAddon addonType="prepend">
              <InputGroupText>
                <i className="ni ni-single-02" />
              </InputGroupText>
            </InputGroupAddon>
            <Input
              placeholder="İsim"
              type="text"
              name="name"
              minLength="3"
              maxLength="40"
              required="required"
              onInput={handleInvalidInput}
              onInvalid={handleInvalidInput}
            />
          </InputGroup>
        </FormGroup>
        <FormGroup className="mb-3">
          <InputGroup className="input-group-alternative">
            <InputGroupAddon addonType="prepend">
              <InputGroupText>
                <i className="ni ni-single-02" />
              </InputGroupText>
            </InputGroupAddon>
            <Input
              placeholder="Soyad"
              type="text"
              name="surname"
              minLength="3"
              maxLength="40"
              required="required"
              onInput={handleInvalidInput}
              onInvalid={handleInvalidInput}
            />
          </InputGroup>
        </FormGroup>
        <FormGroup className="mb-3">
          <InputGroup className="input-group-alternative">
            <InputGroupAddon addonType="prepend">
              <InputGroupText>
                <i className="ni ni-email-83" />
              </InputGroupText>
            </InputGroupAddon>
            <Input
              placeholder="Kişisel Email Adresiniz"
              type="email"
              name="email"
              required="required"
              onInput={handleInvalidInput}
              onInvalid={handleInvalidInput}
            />
          </InputGroup>
        </FormGroup>
        <FormGroup>
          <InputGroup className="input-group-alternative">
            <InputGroupAddon addonType="prepend">
              <InputGroupText>
                <i className="ni ni-lock-circle-open" />
              </InputGroupText>
            </InputGroupAddon>
            <Input
              placeholder="Password"
              type="password"
              name="password"
              autoComplete="off"
              minLength="8"
              required="required"
              onInput={handleInvalidInput}
              onInvalid={handleInvalidInput}
            />
          </InputGroup>
        </FormGroup>
        {/*
        // TODO: Şifre güçlülük kontrol edilecek
        */}
        <div className="text-muted font-italic" style={{display: 'none'}}>
          <small>
            password strength:{' '}
            <span className="text-success font-weight-700">strong</span>
          </small>
        </div>
        <Row className="my-4">
          <Col xs="12">
            <div
              className="custom-control
                              custom-control-alternative custom-checkbox">
              <input
                className="custom-control-input"
                id="customCheckRegister"
                type="checkbox"
                required
                onInput={handleInvalidInput}
                onInvalid={handleInvalidInput}
              />
              <label
                className="custom-control-label"
                htmlFor="customCheckRegister">
                <span>
                  <a href="#" onClick={PrivacyPolicyToggle}>
                    Gizlilik Politikası
                  </a>
                  {`'`}nı okudum, onaylıyorum
                </span>
              </label>
            </div>
          </Col>
        </Row>
        <Row className="my-4">
          <Col xs="12">
            <div
              className="custom-control
                              custom-control-alternative custom-checkbox">
              <input
                className="custom-control-input"
                id="customCheckRegisterKVKK"
                type="checkbox"
                required
                onInput={handleInvalidInput}
                onInvalid={handleInvalidInput}
              />
              <label
                className="custom-control-label"
                htmlFor="customCheckRegisterKVKK">
                <span>
                  <a href="#" onClick={KVKKToggle}>
                    KVKK aydınlatma metnini
                  </a>{' '}
                  okudum, onaylıyorum
                </span>
              </label>
            </div>
          </Col>
        </Row>
        <Row className="my-4">
          <Col xs="12">
            <div className="text-center">
              <Button
                disabled={waitForProcess}
                className="my-4"
                color="primary"
                type="submit">
                Kaydol
              </Button>
            </div>
          </Col>
        </Row>
      </Form>
    </>
  );
};

export default RegisterForm;
