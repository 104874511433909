export default {
  home_Hero_title: 'Yeni nesil, dijital, guvenli kimlik doğrulama servisiniz',
  home_Hero_description:
    'Öğrencilere, öğretmenlere, sağlık calışanlarına ve daha fazlasına yönelik sunmuş olduğunuz teklifler için müşterilerinizi anında doğrulayın.',
  howItWorks_title: 'Nasıl Çalışır?',
  howItWorks_flow1: `Online alışveriş yapan kullanıcılar alışverişini tamamladıktan sonra ödeme adımına geçer. Ödeme aşamasında kullanıcılara özel sunulan indirimlerden yararlanmak için kullanıcının doğrulanması gerekir. Tek yapılması gereken Yetky API'ler kullanılarak kullanıcıların Yetky'e yönlendirilmesidir. `,
  howItWorks_flow2:
    'Yetky hizmetimize yönlendirilen kullanıcılardan kimlik doğrulaması için bazı bilgiler istenir. Örneğin öğrenci ise hangi üniversitede öğrenci olduğu gibi.',
  howItWorks_flow3:
    'Örneğin öğrenciler için indirim uygulanıyorsa öğrencilerden okudukları üniversite ile alakalı bilgileri istenerek öğrenci olduğu doğrulanır.',
  howItWorks_flow4: 'Sepette indirim uygulanır ...',
  howItWorks_flow5: 'Bu kadar basit! Değil mi?',
  login_forgotPassword_button: 'Şifremi Unuttum ?',
  login_failed: 'Giriş başarısız. E-posta ya da şifreniz hatalı',
  login_top_title: 'E-posta ve şifre ile giriş yapın',
  login_remember_me: 'Beni Hatırla',
  login_button: 'Giriş Yap',
  login_signup_button: 'Yeni Hesap Oluştur',
  forgotPassword_title: 'Şifremi Unuttum',
  forgotPassword_success_content:
    'E-posta adresinize şifre sıfırlama bağlantısı gönderildi. Lütfen e-postanızı kontrol edin.',
  forgotPassword_fail_content:
    'E-posta adresinize şifre sıfırlama bağlantısı gönderilemedi. Lütfen e-posta adresinizi kontrol edip tekrar deneyin.',
  forgotPassword_email_validation_error:
    'Lütfen e-posta alanına geçerli bir e-posta adresi girin.',
  forgotPassword_content:
    'Lütfen kayıt olduğunuz e-posta adresinizi yazınız. E-posta adresinize şifre sıfırlama linki gönderilecektir.',
  forgotPassword_email: 'E-posta',
  forgotPassword_button: 'Link Gönder',
  resetPassword_title: 'Yeni Şifre',
  resetPassword_new_password: 'Yeni Şifre',
  resetPassword_confirm_password: 'Şifreyi Onayla',
  resetPassword_confirm_button: 'Şifreyi Sıfırla',
  resetPassword_success_title: 'Şifremi Sıfırla',
  resetPassword_success_content:
    'Şifreniz başarıyla sıfırlandı. Yeni şifrenizle şimdi giriş yapabilirsiniz.',
  resetPassword_fail_content: 'Şifreniz sıfırlanamadı. Lütfen tekrar deneyin.',
  resetPassword_passwords_not_match: 'Şifreler eşleşmiyor.',
  name_place_holder: 'İsim',
  surname_place_holder: 'Soyad',
  phone_number_place_holder: 'Telefon Numaranız',
  birth_date_place_holder: 'Doğum Tarihiniz',
  email_place_holder: 'Kişisel Email Adresiniz',
  gender_female: 'Kadın',
  gender_male: 'Erkek',
  verify_identity_notifacation: 'Kimliğinizi doğrulayınız',
  identity_fetch_error_notification:
    'Doğrulanmış kimlikler çekilirken beklenmedik hata ile karşılaşıldı',
  verify_identity_on_authentication_panel_notification:
    'Kimliklerinizi "Doğrulama" panelinde doğrulayabilirsiniz',
  qr_code_error_notification:
    'Qr kod oluşturulurken beklenmedik hata ile karşılaşıldı',
  qr_code_button: 'Qr Kod Oluştur',
  user_updated: 'Bilgileriniz güncellendi',
  user_updated_error: 'Bilgileriniz güncellenirken hata ile karşılaşıldı',
  verificationFormTabs_mail_verification: 'E-posta ile Doğrulama',
  verificationFormTabs_doc_verification: 'Doküman ile Doğrulama',
};
