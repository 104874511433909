/*eslint-disable*/
import React from 'react';

// reactstrap components
import { Container, Row, Col, Card, CardBody } from 'reactstrap';
import { BiCodeBlock, BiDirections, BiFace, BiIdCard } from 'react-icons/bi';

const Carousel: React.FC = (): JSX.Element => {
  return (
    <section className="section section-shaped">
      <div className="shape shape-style-1 shape-default">
        <span />
        <span />
        <span />
        <span />
        <span />
        <span />
      </div>
      <Container className="py-md">
        <Row
          className="row-grid justify-content-center"
          style={{
            marginBottom: 40,
          }}>
          <Col className="text-center" lg="10">
            <h2 className="display-3 text-white">
              Güvenli çözümlerimizle müşteri sadakatini garantileyin
            </h2>
            <p className="lead text-white">
              Yeni nesil kullanıcı dostu kimlik doğrulama çözümlerimizle,
              müşterilerinizin ürün ve hizmetlerinize olan memnuniyetini ve
              bağlılığını en üst seviyeye taşıyın
            </p>
          </Col>
        </Row>

        <Row className="justify-content-center">
          <Col lg="12">
            <Row className="row-grid">
              <Col lg="3">
                <Card className="card-lift--hover shadow border-0">
                  <CardBody className="py-5">
                    <div
                      className="icon icon-shape
                                  icon-shape-warning rounded-circle mb-4">
                      <BiIdCard />
                    </div>
                    <h6 className="text-warning text-uppercase">
                      Belge Doğrulama
                    </h6>
                    <p className="description mt-3">
                      Belgeleri saniyeler içinde doğrulayarak sahte belge ve
                      dolandırıcılıkların önüne geçin
                    </p>
                    <div></div>
                  </CardBody>
                </Card>
              </Col>
              <Col lg="3">
                <Card className="card-lift--hover shadow border-0">
                  <CardBody className="py-5">
                    <div
                      className="icon icon-shape icon-shape-warning
                                  rounded-circle mb-4">
                      <BiFace />
                    </div>
                    <h6 className="text-warning text-uppercase">Yüz Tanıma</h6>
                    <p className="description mt-3">
                      Biyometrik yüz tanıma sistemi ile kimlik doğrulamada hız
                      ve verimle bir adım öne geçin
                    </p>
                    <div></div>
                  </CardBody>
                </Card>
              </Col>
              <Col lg="3">
                <Card className="card-lift--hover shadow border-0">
                  <CardBody className="py-5">
                    <div
                      className="icon icon-shape
                              icon-shape-warning rounded-circle mb-4">
                      <BiCodeBlock />
                    </div>
                    <h6 className="text-warning text-uppercase">API Hizmeti</h6>
                    <p className="description mt-3">
                      Kimlik doğrulama servisini kullanarak müşterilerinizden
                      hiçbir belge almadan doğrulayın
                    </p>
                    <div></div>
                  </CardBody>
                </Card>
              </Col>
              <Col lg="3">
                <Card className="card-lift--hover shadow border-0">
                  <CardBody className="py-5">
                    <div
                      className="icon icon-shape
                              icon-shape-warning rounded-circle mb-4">
                      <BiDirections />
                    </div>
                    <h6 className="text-warning text-uppercase">
                      Doğru Müşteri
                    </h6>
                    <p className="description mt-3">
                      Hedef kitleye ulaşma ve müşteri devamlılığını sağlamak
                      için verilerinizi analiz edin
                    </p>
                    <div></div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
      {/* SVG separator */}
      <div className="separator separator-bottom separator-skew">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          preserveAspectRatio="none"
          version="1.1"
          viewBox="0 0 2560 100"
          x="0"
          y="0">
          <polygon className="fill-white" points="2560 0 2560 100 0 100" />
        </svg>
      </div>
    </section>
  );
};

export default Carousel;
