import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import VerificationService from '../../common/services/VerificationService';
import { Row, Card, CardBody } from 'reactstrap';
import LoadingForm from '../Loading/LoadingForm';

const STUDENT_COMMUNITY_ID = '3d475bea-113d-4ce8-aa76-8a63c5bd0d9c';

const ProfileVerificationRequestForm: React.FC = (): JSX.Element => {
  const [isVerified, setIsVerified] = useState<boolean>();
  const { verificationRequestToken } = useParams<string>();

  const [progressProperties, setProgressProperties] = useState({
    waitForProcess: false,
  });
  useEffect(() => {
    setProgressProperties({ waitForProcess: false });
    getUserVerifications();
  }, []);

  const getUserVerifications = async () => {
    const verificationTokenCryptDto = {
      token: verificationRequestToken,
      communityId: STUDENT_COMMUNITY_ID,
    };
    const response: Response =
      await VerificationService.getValidationVerificationTokenRequest(
        verificationTokenCryptDto,
      );
    if (response.ok) {
      const data = await response.json();
      setIsVerified(data?.valid);
    } else {
      setIsVerified(false);
    }
    setProgressProperties({ waitForProcess: true });
  };

  return (
    <>
      {progressProperties.waitForProcess ? (
        <Row className="justify-content-center">
          <Card
            style={{
              alignItems: 'center',
              border: 'none',
            }}>
            {isVerified ? (
              <CardBody>
                <img
                  src={require('src/assets/img/verification_result/dogrulanmis_ogrenci.jpeg')}
                  style={{
                    height: '25rem',
                    width: '30rem',
                  }}></img>
              </CardBody>
            ) : (
              <CardBody>
                <img
                  src={require('src/assets/img/verification_result/dogrulanmamis_ogrenci.jpeg')}
                  style={{
                    height: '25rem',
                    width: '30rem',
                  }}></img>
              </CardBody>
            )}
          </Card>
        </Row>
      ) : (
        <LoadingForm />
      )}
    </>
  );
};

export default ProfileVerificationRequestForm;
