import React from 'react';
import { Container, Row, Col } from 'reactstrap';

import { useTranslation } from 'src/common/i18n/I18n';

import vFlow1 from 'src/assets/img/misc/v_flow_1.png';
import vFlow2 from 'src/assets/img/misc/v_flow_2.png';
import vFlow3 from 'src/assets/img/misc/v_flow_3.png';
import vFlow4 from 'src/assets/img/misc/v_flow_4.png';
import { Trans } from 'react-i18next';

const HowItWorks: React.FC = (): JSX.Element => {
  const { t } = useTranslation();

  return (
    <>
      <section className="section section-xl section-shaped pb-250">
        <div className="shape shape-style-1 shape-default">
          <span />
          <span />
          <span />
          <span />
          <span />
          <span />
          <span />
          <span />
          <span />
        </div>

        <Container className="py-lg-md d-flex">
          <div className="col px-0">
            <Row>
              <Col lg="8">
                <h1 className="display-3 text-white">
                  {t('howItWorks_title')}
                </h1>
              </Col>
            </Row>
          </div>
        </Container>

        {/* SVG separator */}
        <div className="separator separator-bottom separator-skew">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            preserveAspectRatio="none"
            version="1.1"
            viewBox="0 0 2560 100"
            x="0"
            y="0">
            <polygon className="fill-white" points="2560 0 2560 100 0 100" />
          </svg>
        </div>
      </section>
      <section className="section section-lg pt-lg-5">
        <Container>
          <Row>
            <Col lg="6" md="6" sm="12" className="yetky-pb-sm-3">
              <Trans i18nKey="howItWorks_flow1" />
            </Col>
            <Col lg="6" md="6" sm="12">
              <img className="img-fluid" src={vFlow1} alt={'v_flow 1'} />
            </Col>
            <Col lg="12">
              <hr />
            </Col>
            <Col lg="6" md="6" sm="12" className="yetky-pb-sm-3">
              {t('howItWorks_flow2')}
            </Col>
            <Col lg="6" md="6" sm="12">
              <img className="img-fluid" src={vFlow2} alt={'v_flow 2'} />
            </Col>
            <Col lg="12">
              <hr />
            </Col>
            <Col lg="6" md="6" sm="12" className="yetky-pb-sm-3">
              {t('howItWorks_flow3')}
            </Col>
            <Col lg="6" md="6" sm="12">
              <img className="img-fluid" src={vFlow3} alt={'v_flow 3'} />
            </Col>
            <Col lg="12">
              <hr />
            </Col>
            <Col lg="6" md="6" sm="12" className="yetky-pb-sm-3">
              {t('howItWorks_flow4')}
            </Col>
            <Col lg="6" md="6" sm="12">
              <img className="img-fluid" src={vFlow4} alt={'v_flow 4'} />
            </Col>
            <Col lg="6" md="6" sm="12" className="yetky-pb-sm-3">
              {t('howItWorks_flow5')}
            </Col>
            <Col lg="6" md="6" sm="12"></Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default HowItWorks;
