import React from 'react';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import FileViewer from 'react-file-viewer';

// @ts-ignore
import file from 'src/KVKK.docx';

interface KVKKModalProps {
  toggle: () => void;
  isOpen: boolean;
}

const KVKKModal: React.FC<KVKKModalProps> = (
  args: KVKKModalProps,
): JSX.Element => {
  const onError = (e) => {
    console.log(e, 'error in file-viewer');
  };

  const type = 'docx';

  return (
    <Modal {...args} scrollable style={{ maxWidth: 'fit-content' }}>
      <ModalHeader toggle={args.toggle}>KVKK Metni</ModalHeader>
      <ModalBody>
        <FileViewer fileType={type} filePath={file} onError={onError} />
      </ModalBody>
    </Modal>
  );
};

export default KVKKModal;
