import React, { PropsWithChildren, useState } from 'react';

import { Navigate, useNavigate, useParams } from 'react-router-dom';
import { connect, ConnectedProps } from 'react-redux';
import { bindActionCreators } from '@reduxjs/toolkit';

// reactstrap components
import {
  Col,
  Container,
  Row,
  Card,
  CardBody,
  CardTitle,
  ListGroup,
  ListGroupItem,
  CardLink,
  Alert,
} from 'reactstrap';
import { FiUser } from 'react-icons/fi';
import {
  MdPassword,
  MdExitToApp,
  MdOutlineVerified,
  MdSecurity,
} from 'react-icons/md';
import ProfileForm from 'src/components/Forms/ProfileForm';
import ProfilePasswordChangeForm from 'src/components/Forms/ProfilePasswordChangeForm';
import ProfileCommunityVerificationForm from 'src/components/Forms/ProfileCommunityVerificationForm';
import ProfileVerifiedCommunitiesForm from 'src/components/Forms/ProfileVerifiedCommunitiesForm';
import ProfileVerificationRequestForm from 'src/components/Forms/ProfileVerificationRequestForm';
import { useSearchParams } from 'react-router-dom';

import { logout } from 'src/state/slices/authSlice';

const allTabs = [
  { header: 'Profilim', component: <ProfileForm /> },
  { header: 'Doğrulama', component: <ProfileCommunityVerificationForm /> },
  { header: 'Kimliklerim', component: <ProfileVerifiedCommunitiesForm /> },
  { header: 'Şifre Değiştir', component: <ProfilePasswordChangeForm /> },
  { header: 'Güvenli Çıkış', component: <Navigate to="/giris" /> },
  { header: 'Doğrulama Sonucu', component: <ProfileVerificationRequestForm /> },
];

const Profile: React.FC<ProfileProps> = (
  props: PropsWithChildren<ProfileProps>,
): JSX.Element => {
  // @ts-ignore
  const [searchParams, setSearchParams] = useSearchParams();
  const { verificationRequestToken } = useParams<string>();
  const param = searchParams.get('p');
  let initialActiveTabId = 0;
  const initialNootification = {
    notificationType: 'info',
    notificationShow: false,
    notificationText: 'Kimliğinizi doğrulayın',
  };

  // choose initial panel
  if (param == 'verification') {
    initialActiveTabId = 1;
  } else if (param == 'ids') {
    initialActiveTabId = 2;
  } else if (param == 'password') {
    initialActiveTabId = 3;
  }

  // choose initial notification
  if (searchParams.get('m') == 'success') {
    initialNootification.notificationShow = true;
    initialNootification.notificationText =
      'İşleminiz başarılı bir şekilde gerçekleşmiştir';
  }

  const [activeTabID, setActiveTabID] = useState(initialActiveTabId);
  // const [token, setToken] = useState<string | null>(AuthService.getCurrentUserToken());

  const { authData, logout } = props;
  const navigate = useNavigate();

  // @ts-ignore
  const [notification, setNotification] = useState(initialNootification);

  const logOut = (): void => {
    logout();
    setTimeout(() => {
      navigate('/giris');
    }, 500);
  };

  const setActiveTab = (activeTabId) => {
    if (verificationRequestToken) {
      navigate('/profil');
    }
    setActiveTabID(activeTabId);
  };

  return (
    <>
      <section className="section section-shaped">
        <Container className="py-md">
          <Row className="justify-content-center">
            <Col
              lg="12"
              style={{
                height: '50px',
              }}></Col>
          </Row>
          <Row className="justify-content-center">
            <Col lg="12">
              <Row className="row-grid">
                <Col lg="3">
                  <Card
                    style={{
                      alignItems: 'center',
                      boxShadow: '0px 0px 1px 1px #ddd',
                      height: '40rem',
                    }}>
                    <CardBody>
                      <img
                        alt="Avatar"
                        src={require('src/assets/img/brand/profile_pic.png')}
                        style={{
                          borderRadius: '50%',
                          border: '1px solid #5e72e473',
                          height: '11rem',
                          margin: '1rem',
                          width: '11rem',
                        }}
                      />
                      <CardTitle
                        tag="h4"
                        style={{ textAlign: 'center', fontSize: '12px' }}>
                        {authData?.username}
                      </CardTitle>
                      <ListGroup flush>
                        <ListGroupItem>
                          <CardLink href="#" onClick={() => setActiveTab(0)}>
                            <FiUser
                              style={{
                                fontSize: '2rem',
                                marginRight: '1rem',
                                background: '#5e72e4',
                                color: 'white',
                                borderRadius: '50%',
                                padding: '0.25rem',
                              }}
                            />
                            {allTabs[0].header}
                          </CardLink>
                        </ListGroupItem>
                        <ListGroupItem>
                          <CardLink href="#" onClick={() => setActiveTab(1)}>
                            <MdSecurity
                              style={{
                                fontSize: '2rem',
                                marginRight: '1rem',
                                background: '#5e72e4',
                                color: 'white',
                                borderRadius: '50%',
                                padding: '0.25rem',
                              }}
                            />
                            {allTabs[1].header}
                          </CardLink>
                        </ListGroupItem>
                        <ListGroupItem>
                          <CardLink href="#" onClick={() => setActiveTab(2)}>
                            <MdOutlineVerified
                              style={{
                                fontSize: '2rem',
                                marginRight: '1rem',
                                background: '#5e72e4',
                                color: 'white',
                                borderRadius: '50%',
                                padding: '0.25rem',
                              }}
                            />
                            {allTabs[2].header}
                          </CardLink>
                        </ListGroupItem>
                        <ListGroupItem>
                          <CardLink href="#" onClick={() => setActiveTab(3)}>
                            <MdPassword
                              style={{
                                fontSize: '2rem',
                                marginRight: '1rem',
                                background: '#5e72e4',
                                color: 'white',
                                borderRadius: '50%',
                                padding: '0.25rem',
                              }}
                            />
                            {allTabs[3].header}
                          </CardLink>
                        </ListGroupItem>
                        <ListGroupItem>
                          <CardLink href="#" onClick={logOut}>
                            <MdExitToApp
                              style={{
                                fontSize: '2rem',
                                marginRight: '1rem',
                                background: '#5e72e4',
                                color: 'white',
                                borderRadius: '50%',
                                padding: '0.25rem',
                              }}
                            />
                            {allTabs[4].header}
                          </CardLink>
                        </ListGroupItem>
                      </ListGroup>
                    </CardBody>
                  </Card>
                </Col>
                <Col lg="9" style={{ textAlign: 'end' }}>
                  <Card
                    className="shadow border-0"
                    style={{
                      height: '40rem',
                      boxShadow: '0px 0px 1px 2px #ddd',
                    }}>
                    <CardBody style={{ padding: '0', flex: 'initial' }}>
                      <CardTitle
                        tag="h5"
                        style={{
                          textAlign: 'start',
                          padding: '1rem 1rem 1rem 3rem',
                          borderBottom: '4px solid #fb634085',
                          color: '#92989f',
                          fontSize: '1.5rem',
                          fontWeight: '600',
                        }}>
                        {verificationRequestToken
                          ? allTabs[5].header
                          : allTabs[activeTabID].header}
                      </CardTitle>
                    </CardBody>
                    <CardBody
                      style={{
                        padding: '1rem 1rem 1rem 3rem',
                        flex: 'initial',
                      }}>
                      <Alert
                        color={notification.notificationType}
                        isOpen={notification.notificationShow}
                        toggle={() => {
                          setNotification({
                            ...notification,
                            notificationShow: false,
                          });
                        }}>
                        <div
                          style={{
                            textAlign: 'center',
                          }}>
                          {notification.notificationText}
                        </div>
                      </Alert>
                      {verificationRequestToken
                        ? allTabs[5].component
                        : allTabs[activeTabID].component}
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ logout }, dispatch);

const mapStateToProps = (state) => {
  return {
    authData: state?.auth,
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type ProfileProps = ConnectedProps<typeof connector>;
export default connector(Profile);
