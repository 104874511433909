import React, {useState} from 'react';

import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Row,
} from 'reactstrap';

import ill2 from 'src/assets/img/ill/ill-2.svg';
import VerificationForm from 'src/views/forms/VerificationForm';

interface IVerificationFormProps {
  verificationId: string;
}

const VerificationFormPanel: React.FC<IVerificationFormProps> = (
  props: IVerificationFormProps,
): JSX.Element => {
  // INITIALIZATION
  const verificationId = props.verificationId;
  const [modalProperties, setModalProperties] = useState({
    defaultModal: false,
    modalContent:
      'Yetky hesabı oluşturuysanız hızlı ' +
      'şekilde yetkilerinizi doğrulayabilirsiniz...',
    modalTitle: 'Yetky Doğrulama',
  });

  const toggleModal = () => {
    setModalProperties({
      ...modalProperties,
      defaultModal: !modalProperties.defaultModal,
      // modalTitle: modalParam.modalTitle,
      // modalContent: modalParam.modalContent,
    });
  };

  return (
    <>
      <section className="section section-shaped section-lg">
        <div className="shape shape-style-1 bg-gradient-default">
          <span />
          <span />
          <span />
          <span />
          <span />
          <span />
          <span />
          <span />
        </div>
        <Container className="pt-lg-5">
          <Row className="justify-content-center">
            <Col lg="5" className="yetky-card-cover-main">
              <Card className="bg-secondary shadow border-0">
                <CardHeader className="bg-white pb-5">
                  <div className="text-muted text-center mb-3">
                    <small>Hızlı doğrulama için</small>
                  </div>
                  <div className="btn-wrapper text-center">
                    <Button
                      className="btn-neutral btn-icon ml-1"
                      color="default"
                      href="#"
                      onClick={() => toggleModal()}>
                      <span className="btn-inner--icon mr-1">
                        <img alt="..." src={ill2} />
                      </span>
                      <span className="btn-inner--text">Yetky</span>
                    </Button>
                  </div>
                </CardHeader>
                <CardBody className="px-lg-4 py-lg-4">
                  <VerificationForm verificationId={verificationId} />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>

        {/* SVG separator */}
        <div className="separator separator-bottom separator-skew zindex-100">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            preserveAspectRatio="none"
            version="1.1"
            viewBox="0 0 2560 100"
            x="0"
            y="0">
            <polygon className="fill-white" points="2560 0 2560 100 0 100" />
          </svg>
        </div>
      </section>
    </>
  );
};

export default VerificationFormPanel;
