import React from 'react';

import {
  Button,
  Col,
  Form,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Row,
} from 'reactstrap';

import {handleInvalidInput} from '../../common/utils/Utils';

interface OrganizationMailSendFormProps {
  handleOrganizationMailSubmit: (event) => void;
  showLoading: boolean;
}

const OrganizationMailSendForm: React.FC<OrganizationMailSendFormProps> = (
  props: OrganizationMailSendFormProps,
): JSX.Element => {
  const {showLoading, handleOrganizationMailSubmit} = props;

  return (
    <Form role="form" onSubmit={handleOrganizationMailSubmit}>
      <FormGroup className="mb-3">
        <div className="text-muted text-center mb-3">
          {/*
                      <small>{progressProperties.verification.selectedOrganization.name} mail doğrulaması için {progressProperties.verification.selectedOrganization.mail} ile biten mail adresinizi giriniz</small>
                      */}
        </div>
        <InputGroup className="input-group-alternative">
          <InputGroupAddon addonType="prepend">
            <InputGroupText>
              <i className="ni ni-email-83" />
            </InputGroupText>
          </InputGroupAddon>
          <Input
            placeholder="Kurumsal Email Adresiniz"
            type="email"
            name="email"
            minLength="3"
            required="required"
            onInput={handleInvalidInput}
            onInvalid={handleInvalidInput}
          />
        </InputGroup>
      </FormGroup>

      <Row className="my-4">
        <Col xs="12">
          <div className="text-center">
            <Button
              disabled={showLoading}
              className="my-4"
              color="primary"
              type="submit">
              Kurumsal mailimi doğrula
            </Button>
          </div>
        </Col>
      </Row>
    </Form>
  );
};

export default OrganizationMailSendForm;
