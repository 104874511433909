import React, {useEffect, useState} from 'react';
import {bindActionCreators} from '@reduxjs/toolkit';
import {FaUniversity} from 'react-icons/fa';
import {BsFillPersonFill} from 'react-icons/bs';
import {AiTwotonePhone} from 'react-icons/ai';
import {handleInvalidInput} from 'src/common/utils/Utils';

import {
  Alert,
  Button,
  Col,
  Form,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Row,
} from 'reactstrap';

import InfoModal from 'src/components/Modals/InfoModal';
import KVKKModal from 'src/components/Modals/KVKKModal';
import PrivacyPolicyModal from 'src/components/Modals/PrivacyPolicy';

import ProfileService from 'src/common/services/ProfileService';
import {connect, ConnectedProps} from 'react-redux';
import {IOrganization, IVerificationFormDto} from 'types/verificationTypes';
import {verificationFormSubmit} from 'src/state/slices/verificationSlice';

type IVerificationFormProps = {
  verificationId: string;
} & IVerificationFormPropsFromState;

interface progressProperties {
  waitForProcess: boolean;
  notification: boolean;
  isOpenAlertBoxColor: string;
  isOpenAlertBox: boolean;
  isOpenAlertBoxText: string;
}

const VerificationForm: React.FC<IVerificationFormProps> = (
  props: IVerificationFormProps,
): JSX.Element => {
  // INITIALIZATION
  let communitySelectDescription = 'Kurumunuzu seçiniz';
  const {
    verificationInfo,
    community,
    organizationList,
    verificationFormSubmit,
    showLoader,
    auth,
  } = props;
  const [notification, setNotification] = useState(false);
  const [infoNotification, setInfoNotification] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [suggestions, setSuggestions] = useState<IOrganization[]>([]);
  const [progressProperties, setProgressProperties] =
    useState<progressProperties>({
      waitForProcess: true,
      notification: false,
      isOpenAlertBoxColor: 'info',
      isOpenAlertBox: false,
      isOpenAlertBoxText: '',
    });
  const [modalProperties, setModalProperties] = useState({
    defaultModal: false,
    modalContent:
      'Yetky hesabı oluşturuysanız hızlı ' +
      'şekilde yetkilerinizi doğrulayabilirsiniz...',
    modalTitle: 'Yetky Doğrulama',
  });

  const [privacyPolicy, setPrivacyPolicy] = useState(false);
  const PrivacyPolicyToggle = () => setPrivacyPolicy(!privacyPolicy);
  const [kvkkModal, setKvkkModal] = useState(false);
  const KVKKToggle = () => setKvkkModal(!kvkkModal);
  const [user, updateUser] = useState<any>({
    username: '',
    firstName: '',
    lastName: '',
    phoneNumber: '',
    birthDate: '',
    gender: '',
  });

  if (community?.name) {
    switch (community.name) {
      case 'STUDENT':
        communitySelectDescription = 'Üniversitenizi seçiniz';
        break;
      case 'SPORT':
        communitySelectDescription = 'Spor Federasyonunu seçiniz';
        break;
      default:
        break;
    }
  }

  useEffect(() => {
    if (verificationInfo?.verificationForm ?? null) {
      const form = verificationInfo.verificationForm;
      updateUser({
        ...user,
        username: form?.email,
        firstName: form?.firstName,
        lastName: form?.lastName,
        phoneNumber: form?.phoneNumber,
      });
      setInputValue(form.organization.name);
    } else {
      fetchUserData();
    }
  }, []);

  // Fetch user from service & display on screen
  const fetchUserData = async () => {
    const response: Response = await ProfileService.getCurrentUser();
    if (response.ok) {
      const data = await response.json();
      updateUser(data);
    }
  };

  const toggleModal = () => {
    setModalProperties({
      ...modalProperties,
      defaultModal: !modalProperties.defaultModal,
      // modalTitle: modalParam.modalTitle,
      // modalContent: modalParam.modalContent,
    });
  };

  const onDismiss = () => {
    setNotification(false);
  };

  const onInfoDismiss = () => {
    setInfoNotification(false);
  };

  const onAlertDismiss = () => {
    setProgressProperties({
      ...progressProperties,
      isOpenAlertBox: false,
    });
  };

  // Handling Organization Input Event
  const handleInputChange = (event) => {
    setInputValue(event.target.value.toLocaleUpperCase('tr-TR'));
    const filteredSuggestions: IOrganization[] = organizationList?.filter(
      (organization: IOrganization) =>
        organization.name
          .toLocaleUpperCase('tr-TR')
          .includes(event.target.value.toLocaleUpperCase('tr-TR')),
    );
    setSuggestions(filteredSuggestions);
  };

  // Handling Submit Verification Form
  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.target);
    const selectedOrganization:
      | {name: string; id: string | number}
      | undefined = organizationList?.find(
      (organization: IOrganization) =>
        organization.name.toLocaleUpperCase('tr-TR') ==
        String(data.get('organization')).toLocaleUpperCase('tr-TR'),
    );

    const verificationFormDto: IVerificationFormDto = {
      firstName: data.get('name') as string,
      lastName: data.get('surname') as string,
      email: data.get('email') as string,
      phoneNumber: data.get('phone') as string,
      organizationId: (selectedOrganization
        ? selectedOrganization['id']
        : null) as string,
      verificationId: verificationInfo?.id,
    };

    if (!selectedOrganization) {
      setProgressProperties({
        ...progressProperties,
        isOpenAlertBoxColor: 'danger',
        isOpenAlertBox: true,
        isOpenAlertBoxText: 'Organizasyonunuzu doğru şekilde seçmediniz.',
      });
    } else {
      verificationFormSubmit({verificationFormDto});
    }
  };

  return (
    <>
      {/* Modal */}
      <InfoModal
        onModalToggle={toggleModal}
        isOpen={modalProperties.defaultModal}
        modalContent={modalProperties.modalContent}
        modalTitle={modalProperties.modalTitle}></InfoModal>
      <PrivacyPolicyModal
        isOpen={privacyPolicy}
        toggle={PrivacyPolicyToggle}></PrivacyPolicyModal>
      <KVKKModal isOpen={kvkkModal} toggle={KVKKToggle}></KVKKModal>

      <div>
        <Alert color="danger" isOpen={notification} toggle={onDismiss}>
          Beklenmeyen bir hata ile karşılaşıldı...
          <a
            href="views/forms/VerificationFormPanel#VerificationForm.tsx"
            className="alert-link">
            Detalı Bilgi{' '}
          </a>{' '}
          üzerinden ulaşabilirsiniz
        </Alert>

        <Alert color="info" isOpen={infoNotification} toggle={onInfoDismiss}>
          Kaydınız başarılı şekilde alındı.
        </Alert>

        <Alert
          color={progressProperties.isOpenAlertBoxColor}
          isOpen={progressProperties.isOpenAlertBox}
          toggle={onAlertDismiss}>
          {progressProperties.isOpenAlertBoxText}
        </Alert>
      </div>

      {/*
// TODO:Spinner eklenecek
<div className="d-flex yetky-card-cover ">
  <Spinner
    color="primary"
    className="yetky-card-cover-spinner"
    style={{
      height: '3rem',
      width: '3rem',
    }}
    type="grow"
  >
  </Spinner>
</div>
*/}

      <Form role="form" onSubmit={handleSubmit}>
        <FormGroup className="mb-3">
          <InputGroup className="input-group-alternative">
            <InputGroupAddon addonType="prepend">
              <InputGroupText>
                <FaUniversity style={{fontSize: '1.1rem'}} />
              </InputGroupText>
            </InputGroupAddon>
            <Input
              placeholder={communitySelectDescription}
              type="text"
              name="organization"
              minLength="3"
              required="required"
              value={inputValue}
              onChange={handleInputChange}
              list="organizations"
              onInput={handleInvalidInput}
              onInvalid={handleInvalidInput}
            />
            <datalist id="organizations">
              {suggestions.map((option) => (
                <option key={option.id} value={option.name} />
              ))}
            </datalist>
          </InputGroup>
        </FormGroup>
        <FormGroup
          className="mb-3"
          style={{display: user.firstName && auth?.username ? 'none' : ''}}>
          <InputGroup className="input-group-alternative">
            <InputGroupAddon addonType="prepend">
              <InputGroupText>
                <BsFillPersonFill style={{fontSize: '1.1rem'}} />
              </InputGroupText>
            </InputGroupAddon>
            <Input
              placeholder="İsim"
              type="text"
              name="name"
              minLength="3"
              maxLength="20"
              required="required"
              defaultValue={user.firstName ? user.firstName : ''}
              onInvalid={handleInvalidInput}
              onInput={handleInvalidInput}
            />
          </InputGroup>
        </FormGroup>
        <FormGroup
          className="mb-3"
          style={{display: user.lastName && auth?.username ? 'none' : ''}}>
          <InputGroup className="input-group-alternative">
            <InputGroupAddon addonType="prepend">
              <InputGroupText>
                <BsFillPersonFill style={{fontSize: '1.1rem'}} />
              </InputGroupText>
            </InputGroupAddon>
            <Input
              placeholder="Soyad"
              type="text"
              name="surname"
              minLength="3"
              maxLength="20"
              required="required"
              defaultValue={user.lastName}
              onInput={handleInvalidInput}
              onInvalid={handleInvalidInput}
            />
          </InputGroup>
        </FormGroup>
        <FormGroup
          className="mb-3"
          style={{display: user.phoneNumber && auth?.username ? 'none' : ''}}>
          <InputGroup className="input-group-alternative">
            <InputGroupAddon addonType="prepend">
              <InputGroupText>
                <AiTwotonePhone style={{fontSize: '1.1rem'}} />
              </InputGroupText>
            </InputGroupAddon>
            <Input
              placeholder="Telefon Numaranız"
              type="phone"
              name="phone"
              defaultValue={user.phoneNumber}
            />
          </InputGroup>
        </FormGroup>
        <FormGroup
          className="mb-3"
          style={{display: user.username && auth?.username ? 'none' : ''}}>
          <InputGroup className="input-group-alternative">
            <InputGroupAddon addonType="prepend">
              <InputGroupText>
                <i className="ni ni-email-83" />
              </InputGroupText>
            </InputGroupAddon>
            <Input
              placeholder="Kişisel Email Adresiniz"
              type="email"
              name="email"
              minLength="3"
              required="required"
              defaultValue={user.username}
              onInput={handleInvalidInput}
              onInvalid={handleInvalidInput}
            />
          </InputGroup>
        </FormGroup>
        {user.username === null && (
          <Row className="my-4">
            <Col xs="12">
              <div
                className="custom-control
    custom-control-alternative custom-checkbox">
                <Input
                  className="custom-control-input"
                  id="customCheckRegister"
                  type="checkbox"
                  required="required"
                  onInput={handleInvalidInput}
                  onInvalid={handleInvalidInput}
                />
                <label
                  className="custom-control-label"
                  htmlFor="customCheckRegister">
                  <span>
                    <a href="#" onClick={PrivacyPolicyToggle}>
                      Gizlilik Politikası
                    </a>
                    {`'`}nı okudum, onaylıyorum
                  </span>
                </label>
              </div>
            </Col>
          </Row>
        )}
        {user.username === null && (
          <Row className="my-4">
            <Col xs="12">
              <div
                className="custom-control
    custom-control-alternative custom-checkbox">
                <Input
                  className="custom-control-input"
                  id="customCheckRegisterKVKK"
                  type="checkbox"
                  required="required"
                  onInput={handleInvalidInput}
                  onInvalid={handleInvalidInput}
                />
                <label
                  className="custom-control-label"
                  htmlFor="customCheckRegisterKVKK">
                  <span>
                    <a href="#" onClick={KVKKToggle}>
                      KVKK aydınlatma metnini
                    </a>{' '}
                    okudum, onaylıyorum
                  </span>
                </label>
              </div>
            </Col>
          </Row>
        )}
        <Row className="my-4">
          <Col xs="12">
            <div className="text-center">
              <Button
                disabled={showLoader}
                className="my-4"
                color="primary"
                type="submit">
                Doğrula
              </Button>
            </div>
          </Col>
        </Row>
      </Form>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    verificationInfo: state.verification.verificationInfo,
    organizationList: state.verification.organizationList,
    community: state.verification.community,
    brand: state.verification.brand,
    showLoader: state.common.showLoader,
    auth: state.auth,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({verificationFormSubmit}, dispatch);

const connector = connect(mapStateToProps, mapDispatchToProps);

type IVerificationFormPropsFromState = ConnectedProps<typeof connector>;
export default connector(VerificationForm);
