import {fetchWithAuth} from 'src/common/services/RequestService';
import {IValidateVerificationEmailDto} from 'types/verificationTypes';

const API_URL = '/api/verification';

/**
 * VerificationService
 */
class VerificationService {
  /**
   *
   * @param {JSON}  createVerificationByUserDto
   * @return {Promise<Response>}
   */
  getUserVerification(createVerificationByUserDto: {communityId: string}) {
    return fetchWithAuth(API_URL + '/user', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(createVerificationByUserDto),
    });
  }

  /**
   *
   * @return {Promise<Response>}
   */
  getUserVerificationList() {
    return fetchWithAuth(API_URL + '/user', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    });
  }

  /**
   *
   * @param {FormData}  formdata
   * @return {Promise<Response>}
   */
  postBackToVerificationInitialState(formdata) {
    return fetchWithAuth(API_URL + '/back-to-form', {
      method: 'POST',
      body: formdata,
      redirect: 'follow',
    });
  }

  /**
   *
   * @param {string}  verificationId
   * @return {Promise<Response>}
   */
  getVerificationInfo(verificationId: string | undefined) {
    return fetch(API_URL + '?id=' + verificationId, {
      method: 'GET',
    });
    // TODO: Buradaki fonksiyonlar işlem yapılmadan promise dönmelidir
    /*
      .then((response: Response | any) => {
        if (!response.ok) {
          localStorage.removeItem('verification');
          throw new Error(response.status);
          // return response;
        } else {
          return response.json();
        }
      })
      .then((response) => {
        // save verification to local storage
        localStorage.setItem('verification', JSON.stringify(response));
        return response;
      });
     */
  }

  /**
   *
   * @param {JSON} formRequestDTO
   * @return {Promise<Response>}
   */
  async postVerificationForm(formRequestDTO) {
    return await fetch(API_URL + '/form', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(formRequestDTO),
    });
  }

  /**
   *
   * @param {JSON} sendVerificationEmailDto
   * @return {Promise<Response>}
   */
  postVerificationMail(sendVerificationEmailDto) {
    return fetch(API_URL + '/email/send', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(sendVerificationEmailDto),
    });
  }

  /**
   *
   * @param {JSON} validateVerificationEmailDto
   * @return {Promise<Response>}
   */
  postVerificationMailCode(
    validateVerificationEmailDto: IValidateVerificationEmailDto,
  ) {
    return fetch(API_URL + '/email/validate', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(validateVerificationEmailDto),
    });
  }

  /**
   *
   * @param {FormData} formData
   * @return {Promise<Response>}
   */
  postVerificationDocuments(formData) {
    return fetch(API_URL + '/document/upload', {
      method: 'POST',
      body: formData,
    });
  }

  /**
   * @param {JSON}  verificationJSON
   */
  redirectToBeforeVerificationPage(verificationJSON) {
    if (verificationJSON.state == 'COMPLETED') {
      const redirectUrl = verificationJSON.returnUrl;
      if (redirectUrl) {
        window.location.replace(redirectUrl);
      } else {
        window.location.replace('/profil?p=ids&m=success');
      }
    }
  }

  /**
   *
   * @param {JSON} verification
   * @return {String}
   */
  getVerificationStatusDescription(verification) {
    if (verification) {
      if (verification.errorCause) {
        switch (verification.errorCause) {
          case 'INVALID_VALIDATION_CODE':
            return 'Geçersiz doğrulama kodu';
          default:
            break;
        }
      }
    }

    return 'Bilinmeyen Durum';
  }

  /**
   * Bu fonsiyon ile doğrulanmış topluluk bilgilerinin servis üzerinden çekilmesi sağlanır
   *
   * @return {Promise<Response>}
   */
  getUserVerifiedCommunities(): Promise<Response> {
    return fetchWithAuth(API_URL + '/user/verified-ids', {
      method: 'GET',
    });
  }

  /**
   * Bu fonsiyon ile qr code token bilgisinin servis üzerinden çekilmesi sağlanır
   *
   * @return {Promise<Response>}
   */
  getVerificationTokenCrypt(): Promise<Response> {
    return fetchWithAuth(API_URL + '/user/verified-token', {
      method: 'GET',
    });
  }

  /**
   * Bu fonsiyon ile qr okutulduktan sonra kullanıcının doğrulanmış kimlik bilgisinin servis üzerinden çekilmesi sağlanır
   * @param {string} verificationTokenCryptDto
   * @return {Promise<Response>}
   */
  getValidationVerificationTokenRequest(
    verificationTokenCryptDto: any,
  ): Promise<Response> {
    return fetchWithAuth(API_URL + '/validate-verified-token', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(verificationTokenCryptDto),
    });
  }
}

export default new VerificationService();
