/**
 * En az 8 en fazla 20 karakter olacak şekilde en az bir büyük harf, en az bir küçük harf ve en az bir özel karakter
 * içerip içermediği kontrol edilir
 *
 * @param {string} password
 * @return {boolean}
 */
function checkPasswordStrength(password) {
  const regex =
    /^(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_\-+={}[\]\\|;:'",.<>/?]).{8,20}$/;
  return regex.test(password);
}

/**
 * @description
 *  JWT token parse edilmesini sağlar.
 *  {header, payload} döner
 *
 * @param {string} token
 * @return {JSON}
 *
 */
export const parseJwtToken = (token) => {
  const tokenWithoutBearer = token.replace('Bearer ', '');
  const headerBase64 = tokenWithoutBearer.split('.')[0];
  const payloadBase64 = tokenWithoutBearer.split('.')[1];
  const header = JSON.parse(
    atob(headerBase64.replace('-', '+').replace('_', '/')),
  );
  const payload = JSON.parse(
    atob(payloadBase64.replace('-', '+').replace('_', '/')),
  );
  return { header, payload };
};

/**
 * @description
 *  JWT token içinden mail dönmesi sağlanır.
 *  Eğer mail bulunamazsa '' döner.
 *
 * @param {string} token
 * @return {string}
 */
export const parseUsernameInJWT = (token) => {
  try {
    // @ts-ignore
    const { header, payload } = parseJwtToken(token);
    const sub = payload.sub;
    const username = sub.split(',')[1];
    return username;
  } catch (e) {
    return '';
  }
};

export const handleInvalidInput = (event) => {
  const input = event.target;
  if (input.validity.valueMissing) {
    if (input.id == 'customCheckRegisterKVKK') {
      input.setCustomValidity('Lütfen KVKK maddelerini okuyup onaylayınız');
    } else if (input.id == 'customCheckRegister') {
      input.setCustomValidity('Lütfen Gizlilik polikamızı okuyup onaylayınız');
    } else {
      input.setCustomValidity('Lütfen bu alanı doldurunuz');
    }
  } else if (input.validity.tooShort) {
    const tooShortValue = input.getAttribute('minLength');
    input.setCustomValidity(
      'Lütfen en az ' + tooShortValue + ' karakterli bir değer giriniz',
    );
  } else if (input.validity.tooLong) {
    const tooLongValue = input.getAttribute('maxLength');
    input.setCustomValidity(
      'Lütfen en fazla ' + tooLongValue + ' karakterli bir değer giriniz',
    );
  } else if (input.validity.patternMismatch) {
    input.setCustomValidity('Mail adresinizde @ arada bulunmalıdır');
  } else if (input.validity.typeMismatch) {
    input.setCustomValidity('Mail adresinizde @ arada bulunmalıdır');
  } else if (input.validity.stepMismatch) {
    input.setCustomValidity('Lütfen şartlarımızı okuyup onaylayınız');
  } else {
    input.setCustomValidity('');
  }

  if (input.type === 'password' && input.id !== 'loginPasswordInputElement') {
    if (!checkPasswordStrength(input.value)) {
      input.setCustomValidity(
        'Şifreniz en az 8 en fazla 20 karakterli olup bir büyük harf, bir küçük harf ve bir özel karakter içermelidir',
      );
    }
  }
};

export const getErrorMessage = (errorCode: string) => {
  let message = '';
  switch (errorCode) {
    case 'RECENTLY_SENT':
      message =
        'Yakın zamanda kodunuz gönderildi. Mail adresinizi kontrol edip deneyebilir ya da daha sonra tekrar kod talep edebilirsiniz';
      break;
    case 'ALREADY_VERIFIED':
      message = 'Mailiniz daha önce doğrulanmıştır';
      break;
    case 'MAIL_MISMATCH':
      message = 'Mail adresiniz seçtiğiniz kuruma ait olmalıdır';
      break;
    case 'INAPPROPRIATE_FILE_CONTENT':
      message = 'Döküman tipiniz .pdf .jpg .png formatlarından biri olmalıdır';
      break;
    case 'MAX_UPLOAD_SIZE_EXCEEDED':
      message = 'Maksimum dosya boyutu 5MB olmalıdır';
      break;
    case 'VALIDATION_ATTEMPTS_EXCEEDED':
      message =
        'Doğrulama denemeniz aşıldı, yeni doğrulama kodu alarak tekrar deneyiniz.';
      break;
    case 'INVALID_VALIDATION_CODE':
      message = 'Doğrulama kodu hatalı...';
      break;
    case 'VALIDATION_CODE_EXPIRED':
      message =
        'Doğrulama kodu zaman aşımına uğradı. Lütfen yeni kod alarak tekrardan deneyiniz.';
      break;
    case 'USER_ALREADY_EXIST':
      message = 'Bu e-posta adresi zaten kullanılıyor.';
      break;
    default:
      message = 'Beklenmedik bir durum ile karşılaşıldı';
      break;
  }
  return message;
};

export const formatDate = (date: Date) => {
  const formatDate =
    date.getDate() < 10 ? `0${date.getDate()}` : date.getDate();
  const formatMonth =
    date.getMonth() + 1 < 10 ? `0${date.getMonth() + 1}` : date.getMonth() + 1;
  const formattedDate = [date.getFullYear(), formatMonth, formatDate].join('-');
  return formattedDate;
};

export const translateCommunityName = (name: string) => {
  switch (name) {
    case 'STUDENT':
      return 'ÖĞRENCİ';
    case 'TEACHER':
      return 'ÖĞRETMEN';
    case 'SPORT':
      return 'SPORCU';
    case 'MEDICAL PROFESSIONAL':
      return 'SAĞLIK ÇALIŞANI';
    case 'MILITARY':
      return 'ASKER';
    default:
      return '';
  }
};

export const sleep = (ms) => new Promise((r) => setTimeout(r, ms));
