import React, { PropsWithChildren } from 'react';
import { Navigate, Outlet, useParams } from 'react-router-dom';
import { AppProps } from 'routing/Routes';

const PrivateRoute: React.FC<AppProps> = (
  props: PropsWithChildren<AppProps>,
): JSX.Element => {
  const { authData } = props;
  const { verificationRequestToken } = useParams<string>();
  let url = '';
  if (verificationRequestToken) {
    url = '/giris?token=' + encodeURIComponent(verificationRequestToken);
  } else {
    url = '/giris';
  }
  return authData.isLoggedin ? (
    <>
      <Outlet />
    </>
  ) : (
    <Navigate to={url} />
  );
};

export default PrivateRoute;
