import React, { useState } from 'react';

// reactstrap components
import {
  Alert,
  Button,
  Card,
  CardHeader,
  CardBody,
  Container,
  Row,
  Col,
  Spinner,
} from 'reactstrap';

import github from 'src/assets/img/icons/common/github.svg';
import google from 'src/assets/img/icons/common/google.svg';

import InfoModal from '../../components/Modals/InfoModal';
import PrivacyPolicyModal from '../../components/Modals/PrivacyPolicy';
import KVKKModal from '../../components/Modals/KVKKModal';
import RegisterForm from '../../components/Forms/RegisterForm';
import Loading from 'src/components/Loading/Loading';

const RegisterPage: React.FC = (): JSX.Element => {
  const [modalProperties, setModalProperties] = useState({
    defaultModal: false,
    modalContent:
      'Şuan için hızlı kayıt yapılamamaktadır. En kısa zamanda etkinleştirilecektir.',
    modalTitle: 'Hızlı Kayıt',
  });

  const [progressProperties, setProgressProperties] = useState({
    waitForProcess: false,
  });

  const [notification, setNotification] = useState(false);
  const [infoNotification, setInfoNotification] = useState(false);

  const onDismiss = () => {
    setNotification(false);
  };

  const onInfoDismiss = () => {
    setInfoNotification(false);
  };

  const toggleModal = () => {
    setModalProperties({
      ...modalProperties,
      defaultModal: !modalProperties.defaultModal,
      // modalTitle: modalParam.modalTitle,
      // modalContent: modalParam.modalContent,
    });
  };

  const [privacyPolicy, setPrivacyPolicy] = useState(false);
  const PrivacyPolicyToggle = () => setPrivacyPolicy(!privacyPolicy);
  const [kvkkModal, setKvkkModal] = useState(false);
  const KVKKToggle = () => setKvkkModal(!kvkkModal);

  // RENDER PROPER COMPONENT
  if (progressProperties.waitForProcess) {
    return <Loading />;
  }

  return (
    <>
      {/* Modal */}
      <InfoModal
        onModalToggle={toggleModal}
        isOpen={modalProperties.defaultModal}
        modalContent={modalProperties.modalContent}
        modalTitle={modalProperties.modalTitle}></InfoModal>
      <PrivacyPolicyModal
        isOpen={privacyPolicy}
        toggle={PrivacyPolicyToggle}></PrivacyPolicyModal>
      <KVKKModal isOpen={kvkkModal} toggle={KVKKToggle}></KVKKModal>

      <section className="section section-shaped section-lg">
        <div className="shape shape-style-1 bg-gradient-default">
          <span />
          <span />
          <span />
          <span />
          <span />
          <span />
          <span />
          <span />
        </div>
        <Container className="pt-lg-5">
          <Row className="justify-content-center">
            <Col lg="5">
              <div>
                <Alert color="danger" isOpen={notification} toggle={onDismiss}>
                  Beklenmeyen bir hata ile karşılaşıldı...
                  <a href="#" className="alert-link">
                    Detalı Bilgi{' '}
                  </a>{' '}
                  üzerinden ulaşabilirsiniz
                </Alert>

                <Alert
                  color="info"
                  isOpen={infoNotification}
                  toggle={onInfoDismiss}>
                  Kaydınız başarılı şekilde alındı. Giriş sayfasına
                  yönlendirileceksiniz.
                </Alert>
              </div>

              <Card className="bg-secondary shadow border-0">
                <CardHeader
                  style={{ display: 'none' }}
                  className="bg-white pb-5">
                  <div className="text-muted text-center mb-3">
                    <small>Hızlı kayıt için</small>
                  </div>
                  <div className="btn-wrapper text-center">
                    <Button
                      className="btn-neutral btn-icon"
                      color="default"
                      href=""
                      // @ts-ignore
                      onClick={() => toggleModal()}>
                      <span className="btn-inner--icon mr-1">
                        <img alt="..." src={github} />
                      </span>
                      <span className="btn-inner--text">Github</span>
                    </Button>
                    <Button
                      className="btn-neutral btn-icon ml-1"
                      color="default"
                      href="#"
                      // @ts-ignore
                      onClick={() => toggleModal()}>
                      <span className="btn-inner--icon mr-1">
                        <img alt="..." src={google} />
                      </span>
                      <span className="btn-inner--text">Google</span>
                    </Button>
                  </div>
                </CardHeader>
                <CardBody className="px-lg-4 py-lg-6">
                  <div
                    className="text-center text-muted mb-4"
                    style={{
                      display: progressProperties.waitForProcess
                        ? 'block'
                        : 'none',
                    }}>
                    <Spinner
                      style={{ width: '4rem', height: '4rem' }}
                      type="grow"
                      color="warning"
                    />
                  </div>

                  <RegisterForm
                    waitForProcess={progressProperties.waitForProcess}
                    // @ts-ignore
                    setProgressProperties={setProgressProperties}
                    PrivacyPolicyToggle={PrivacyPolicyToggle}
                    KVKKToggle={KVKKToggle}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>

        {/* SVG separator */}
        <div className="separator separator-bottom separator-skew zindex-100">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            preserveAspectRatio="none"
            version="1.1"
            viewBox="0 0 2560 100"
            x="0"
            y="0">
            <polygon className="fill-white" points="2560 0 2560 100 0 100" />
          </svg>
        </div>
      </section>
    </>
  );
};

export default RegisterPage;
