import React from 'react';
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Row,
} from 'reactstrap';

const VerificationNotFoundForm: React.FC = (): JSX.Element => {
  return (
    <>
      <section className="section section-shaped section-lg">
        <div className="shape shape-style-1 bg-gradient-default">
          <span />
          <span />
          <span />
          <span />
          <span />
          <span />
          <span />
          <span />
        </div>
        <Container className="pt-lg-5">
          <Row className="justify-content-center">
            <Col lg="5" className="yetky-card-cover-main">
              <Card className="bg-secondary shadow border-0">
                <CardHeader className="bg-white pb-3">
                  <div className="text-muted text-center mb-2">
                    <small>Doğrulama Formu Bulunamadı :(</small>
                  </div>
                </CardHeader>
                <CardBody className="px-lg-4 py-lg-4">
                  <div className="text-center text-muted mb-4">
                    <div className="text-center">
                      <Button className="my-4" color="primary" href="/">
                        Anasayfaya dön
                      </Button>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>

        {/* SVG separator */}
        <div className="separator separator-bottom separator-skew zindex-100">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            preserveAspectRatio="none"
            version="1.1"
            viewBox="0 0 2560 100"
            x="0"
            y="0">
            <polygon className="fill-white" points="2560 0 2560 100 0 100" />
          </svg>
        </div>
      </section>
    </>
  );
};

export default VerificationNotFoundForm;
