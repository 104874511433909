import React from 'react';
import QRCode from 'react-qr-code';

interface QRCodeProps {
  qrCode: string;
  width: any;
}

const QRCodeComponent: React.FC<QRCodeProps> = (
  props: QRCodeProps,
): JSX.Element => {
  return (
    <QRCode
      value={props.qrCode}
      style={{ height: 'auto', maxWidth: props.width, width: '100%' }}
      size={256}
      viewBox={'0 0 256 256'}
    />
  );
};

export default QRCodeComponent;
