import React, {useEffect, useState} from 'react';
import {
  Button,
  Col,
  Form,
  Row,
} from 'reactstrap';

import {bindActionCreators} from '@reduxjs/toolkit';
import {
  getVerificationInfo,
  organizationMailConfirmSubmit,
  setNotificationInfo,
} from 'src/state/slices/verificationSlice';
import {connect, ConnectedProps, useDispatch} from 'react-redux';
import {useParams} from 'react-router-dom';

type VerificationStatusCheckFormProps = PropsFromRedux;

const VerificationStatusCheckForm: React.FC<
  VerificationStatusCheckFormProps
> = (props: VerificationStatusCheckFormProps): JSX.Element => {
  const {
    getVerificationInfo,
    showLoader,
    verificationInfo,
  } = props;
  const [notifMessage, setNotifMessage] = useState({
    message: 'Mail adresinize doğrulama linki yollandı.',
    show: false,
  });
  const [infoMessage, setInfoMessage] = useState({
    message: 'Onaylanması için mailinize gelen linki tıklamanız gerekmektedir.',
    show: false,
  });
  const {verificationId} = useParams<string>();

  const dispatch = useDispatch();
  useEffect(() => {
    if (verificationInfo.type == 'DOC') {
      setInfoMessage({message: 'Dökümanlarınız incelenmektedir. ', show: true});
      setNotifMessage({
        message: 'Dökümanlarınız tarafımıza ulaşmıştır.',
        show: true,
      });
    } else {
      setInfoMessage({...infoMessage, show: true});
      setNotifMessage({...notifMessage, show: true});
    }
  }, [verificationInfo]);

  const handleGetVerificationStatus = (event) => {
    event.preventDefault();
    if (verificationId) {
      getVerificationInfo({verificationId});
      const msg = 'Yetky doğrulamanız onaylanmamıştır. Dökümanlarınız incelenmektedir.';
      dispatch(setNotificationInfo({
        notification: true,
        isOpenAlertBoxColor: 'info',
        isOpenAlertBox: true,
        isOpenAlertBoxText: msg,
      }));
    }
  };

  return (
    <>
      <Form role="form" onSubmit={handleGetVerificationStatus}>
        <Row className="my-4">
          <Col xs="12">
            <div className="text-center">
              <div>Yetky doğrulamanız beklenmektedir</div>
            </div>
          </Col>
        </Row>
        <Row className="my-4">
          <Col xs="12">
            <div className="text-center">
              <Button
                disabled={showLoader}
                className="my-4"
                color="primary"
                type="submit">
                Doğrulamayı Kontrol Et
              </Button>
            </div>
          </Col>
        </Row>
      </Form>
    </>
  );
};

const mapStateToProps = (state: any) => {
  return {
    verificationInfo: state.verification.verificationInfo,
    showLoader: state.common.showLoader,
    notificationInfo: state.verification.notificationInfo,
  };
};

const mapDispatchToProps = (dispatch: any) =>
  bindActionCreators(
    {
      organizationMailConfirmSubmit,
      getVerificationInfo,
      setNotificationInfo,
    },
    dispatch,
  );

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;
export default connector(VerificationStatusCheckForm);
