import React from 'react';

import Hero from 'src/components/Sections/Hero';
import Business from 'src/components/Sections/Business';
import Carousel from 'src/components/Sections/Carousel';
import WorkFlowVerificationForm from 'src/components/Sections/WorkFlowVerificationForm';

const Home: React.FC = (): JSX.Element => {
  return (
    <>
      <Hero />
      <Business />
      <Carousel />
      <WorkFlowVerificationForm />
      {/*
            <BusinessTypeSolutionSection />
        */}
    </>
  );
};

export default Home;
