/*eslint-disable*/
import React from 'react';

// reactstrap components
import { Container, Row, Col, Card, CardImg, CardText } from 'reactstrap';

import 'src/assets/css/Download.css';
import create from 'src/assets/img/brand/create.jpg';
import convert from 'src/assets/img/brand/convert.jpg';
import verify from 'src/assets/img/brand/verify.jpg';

const Business: React.FC = (): JSX.Element => {
  return (
    <section>
      <Container className="row-grid justify-content-center">
        <Row id="step-container">
          <Col id="step-1" lg="4">
            <Card className="step-card">
              <div className="circle3">1</div>
              <h2 className="display-3">OLUŞTUR</h2>
              <CardText style={{ paddingLeft: '20px' }}>
                Doğrudan e-ticaret akışınızda özel bir teklif oluşturun.
              </CardText>
              <CardImg alt="..." src={create} />
            </Card>
          </Col>
          <Col id="step-2" lg="4">
            <Card className="step-card">
              <div className="circle3">2</div>
              <h2 className="display-3">DOĞRULA</h2>
              <CardText style={{ paddingLeft: '20px' }}>
                Kimlik sağlayıcılar, topluluk e-maili veya kimlik & fotoğraf
                üzerinden kişinin uygunluğu doğrulansın.
              </CardText>
              <CardImg alt="..." src={verify} />
            </Card>
          </Col>
          <Col id="step-3" lg="4">
            <Card className="step-card">
              <div className="circle3">3</div>
              <h2 className="display-3">ULAŞTIR</h2>
              <CardText style={{ paddingLeft: '20px', paddingRight: '20px' }}>
                Teklifinizi hedeflediğiniz müşterilerinize ulaştığına emin olun.
              </CardText>
              <CardImg alt="..." src={convert} />
            </Card>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Business;
