import React, {PropsWithChildren, useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';
import {bindActionCreators} from '@reduxjs/toolkit';
import {connect, ConnectedProps} from 'react-redux';
import VerificationFormPanel from 'src/views/forms/VerificationFormPanel';
import FailedVerification from 'src/views/pages/FailedVerification';
import VerificationNotFoundForm from 'src/views/forms/VerificationNotFoundForm';
import VerificationFormTabs from 'src/views/forms/VerificationFormTabs';
import VerificationService from 'src/common/services/VerificationService';
import {VerificationState} from 'src/common/constants/enums';
import Loading from 'src/components/Loading/Loading';
import InfoModal from 'src/components/Modals/InfoModal';
import {
  getVerificationInfo,
  organizationMailConfirmSubmit,
} from 'src/state/slices/verificationSlice';

const VerificationRequest: React.FC<IVerificationRequestProps> = (
  props: PropsWithChildren<IVerificationRequestProps>,
): JSX.Element => {
  const {getVerificationInfo, verificationInfo, showLoader} = props;
  // USE PARAMS
  const {verificationId} = useParams<string>();
  // USE STATE

  const [modalProperties, setModalProperties] = useState({
    defaultModal: false,
    modalContent:
      'Yetky hesabı oluşturuysanız hızlı ' +
      'şekilde yetkilerinizi doğrulayabilirsiniz...',
    modalTitle: 'Yetky Doğrulama',
  });

  useEffect(() => {
    if (verificationId) {
      getVerificationInfo({verificationId});
    }
  }, []);

  const toggleModal = () => {
    setModalProperties({
      ...modalProperties,
      defaultModal: !modalProperties.defaultModal,
      // modalTitle: modalParam.modalTitle,
      // modalContent: modalParam.modalContent,
    });
  };

  return (
    <>
      <InfoModal
        onModalToggle={toggleModal}
        isOpen={modalProperties.defaultModal}
        modalContent={modalProperties.modalContent}
        modalTitle={modalProperties.modalTitle}
      />

      {showLoader && <Loading />}

      {verificationInfo.state === VerificationState.Initial &&
        <VerificationFormPanel verificationId={verificationId as string} />}

      { (verificationInfo.state === VerificationState.Submitted ||
          verificationInfo.state === VerificationState.Started) &&
          <VerificationFormTabs
              toggleModal={toggleModal}
              setModalProperties={setModalProperties}
          />}

      { verificationInfo.state === VerificationState.Completed &&
        VerificationService.redirectToBeforeVerificationPage(verificationInfo)}

      { !(verificationInfo?.state ?? null) &&
          <VerificationNotFoundForm />}

      { verificationInfo?.state === VerificationState.Error &&
          <FailedVerification reason={'unknownStatus'} />}

    </>
  );
};

const mapStateToProps = (state: any) => {
  return {
    verificationInfo: state.verification.verificationInfo,
    showLoader: state.common.showLoader,
    notificationInfo: state.verification.notificationInfo,
  };
};

const mapDispatchToProps = (dispatch: any) =>
  bindActionCreators(
    {
      organizationMailConfirmSubmit,
      getVerificationInfo,
    },
    dispatch,
  );

const connector = connect(mapStateToProps, mapDispatchToProps);
type IVerificationRequestProps = ConnectedProps<typeof connector>;
export default connector(VerificationRequest);
