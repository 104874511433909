// create a new component called ForgotPasswordModal

import React, { PropsWithChildren } from 'react';
import {
  Button,
  Modal,
  Form,
  FormGroup,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Input,
  FormFeedback,
  Alert,
} from 'reactstrap';
import { useTranslation } from 'src/common/i18n/I18n';
import { handleInvalidInput } from 'src/common/utils/Utils';
import AuthService from 'src/common/services/AuthService';

interface ForgotModalProps {
  onModalToggle: (modalParam) => void;
  isOpen: boolean;
}

const ForgotPasswordModal: React.FC<ForgotModalProps> = (
  props: PropsWithChildren<ForgotModalProps>,
): JSX.Element => {
  const { onModalToggle, isOpen } = props;
  const [emailError, setEmailError] = React.useState<string>('');
  const [progress, setProgress] = React.useState<boolean>(false);
  const [finished, setFinished] = React.useState<{
    finished: boolean;
    content: string;
    status: boolean;
  }>({ finished: false, content: '', status: false });
  const { t } = useTranslation();

  const forgotPassword = (e) => {
    const formData = new FormData(e.target);
    console.log('event=', formData.get('email'));
    const email: string = formData.get('email')?.toString() || '';
    e.preventDefault();
    if (email === '') {
      setEmailError(t('forgotPassword_email_validation_error'));
    } else {
      setProgress(true);
      AuthService.forgotPassword(email).then((response) => {
        setProgress(false);
        if (response.ok) {
          setFinished({
            finished: true,
            content: t('forgotPassword_success_content'),
            status: true,
          });
          setTimeout(() => {
            setFinished({
              finished: false,
              content: '',
              status: false,
            });
          }, 3000);
        } else {
          setFinished({
            finished: true,
            content: t('forgotPassword_fail_content'),
            status: false,
          });
          setTimeout(() => {
            setFinished({
              finished: false,
              content: '',
              status: false,
            });
          }, 3000);
        }
      });
    }
  };

  return (
    <Modal
      className="modal-dialog-centered"
      isOpen={isOpen}
      toggle={onModalToggle}>
      <div className="modal-header">
        <h5 className="modal-title" id="exampleModalLabel">
          {t('forgotPassword_title')}
        </h5>
        <button
          aria-label="Close"
          className="close"
          data-dismiss="modal"
          type="button"
          onClick={onModalToggle}>
          <span aria-hidden={true}>×</span>
        </button>
      </div>

      <div className="modal-body">
        <div style={{ paddingBottom: '20px' }}>
          {!finished.finished && t('forgotPassword_content')}
          {finished.finished && (
            <Alert color={!finished.status ? 'danger' : 'success'}>
              {finished.content}
            </Alert>
          )}
        </div>
        <Form onSubmit={forgotPassword}>
          <FormGroup className="mb-3">
            <InputGroup className="input-group-alternative">
              <InputGroupAddon addonType="prepend">
                <InputGroupText>
                  <i className="ni ni-email-83" />
                </InputGroupText>
              </InputGroupAddon>
              <Input
                placeholder={t('forgotPassword_email')}
                type="email"
                name="email"
                required="required"
                onInput={handleInvalidInput}
                onInvalid={handleInvalidInput}
              />
              {emailError !== '' && <FormFeedback>{emailError}</FormFeedback>}
            </InputGroup>
          </FormGroup>
          <div className="text-center">
            <Button
              className="my-4"
              color="primary"
              type="submit"
              disabled={progress}>
              {t('forgotPassword_button')}
            </Button>
          </div>
        </Form>
      </div>

      <div className="modal-footer">
        <Button
          className="ml-auto"
          color="link"
          data-dismiss="modal"
          type="button"
          onClick={onModalToggle}>
          Kapat
        </Button>
      </div>
    </Modal>
  );
};

export default ForgotPasswordModal;
