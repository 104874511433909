import React from 'react';

import { Spinner } from 'reactstrap';

import 'src/assets/css/yetky-theme.scss';

const Loading: React.FC = (): JSX.Element => {
  return (
    <>
      <div className="d-flex yetky-card-cover ">
        <Spinner
          color="primary"
          className="yetky-card-cover-spinner"
          style={{
            height: '3rem',
            width: '3rem',
          }}
          type="grow"></Spinner>
      </div>
    </>
  );
};

export default Loading;
