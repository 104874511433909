import {
  Alert,
  Button,
  Col,
  Form,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Row,
} from 'reactstrap';
import React, { PropsWithChildren, useState } from 'react';
import AuthService from 'src/common/services/AuthService';
import { getErrorMessage } from 'src/common/utils/Utils';
import { useNavigate } from 'react-router-dom';
import { bindActionCreators } from '@reduxjs/toolkit';
import { loginAfterRegister } from 'src/state/slices/authSlice';
import { connect, ConnectedProps } from 'react-redux';

const MailConfirmForm: React.FC<MailConfirmFormProps> = (
  props: PropsWithChildren<MailConfirmFormProps>,
): JSX.Element => {
  // INITIALIZATION
  const navigate = useNavigate();
  const [notification, setNotification] = useState({
    notificationText: '',
    notificationType: 'danger',
    notificationShow: false,
  });

  const { loginAfterRegister } = props;

  // MAIL VALIDATION
  const handleMailConfirmSubmit = async (event) => {
    event.preventDefault();
    const data = new FormData(event.target);
    const validationCodeDto = {
      validationCode: data.get('validationCode'),
      username: localStorage.getItem('username'),
    };

    const response: Response = await AuthService.validateMailCode(
      validationCodeDto,
    );
    if (response.ok) {
      setNotification({
        ...notification,
        notificationType: 'info',
        notificationText:
          'Mailiniz başarılı şekilde doğrulandı. Giriş sayfasına yönlendirileceksiniz',
        notificationShow: true,
      });
      const data = await response.json();
      loginAfterRegister(JSON.stringify(data));
      navigate('/profil');
    } else if (response?.status == 400) {
      setNotification({
        ...notification,
        notificationType: 'danger',
        notificationText:
          'Mail adresiniz oturumdan düştüğü için kayıt sayfasına yönlendirileceksiniz',
        notificationShow: true,
      });
      setTimeout(() => {
        navigate('/kayit');
      }, 3000);
    } else if (response?.status == 500) {
      const data = await response.json();
      const errorMsg = getErrorMessage(data.message);
      setNotification({
        ...notification,
        notificationType: 'danger',
        notificationText: errorMsg,
        notificationShow: true,
      });
    } else {
      setNotification({
        ...notification,
        notificationType: 'danger',
        notificationText: 'Doğrulama işleminde hata ile karşılaşıldı',
        notificationShow: true,
      });
    }
  };

  // RESEND MAIL VALIDATION CODE
  const handleResendValidationCodeSubmit = (event) => {
    event.preventDefault();
    const resendValidationCodeDto = {
      username: localStorage.getItem('username'),
    };
    AuthService.resendValidateMailCode(resendValidationCodeDto)
      // mail kod doğrulanması durumunda giris sayfasına yönlendirilir
      .then(() => {
        setNotification({
          ...notification,
          notificationType: 'info',
          notificationText: 'Mail adresinize doğrulama kodu gönderildi',
          notificationShow: true,
        });
      })
      // mail kod doğrulama işleminde hata ile karşılaşılması durumunda hata bilgisi verilir
      .catch(() => {
        setNotification({
          ...notification,
          notificationType: 'danger',
          notificationText:
            'Mail adresinize doğrulama kodu gönderilirken hata ile karşılaşıldı',
          notificationShow: true,
        });
      });
  };

  return (
    <>
      <Alert
        color={notification.notificationType}
        isOpen={notification.notificationShow}
        toggle={() => {
          setNotification({
            ...notification,
            notificationShow: false,
          });
        }}>
        {notification.notificationText}
      </Alert>

      <Form role="form" onSubmit={handleMailConfirmSubmit}>
        <FormGroup className="mb-3">
          <div className="text-muted text-left mb-3">
            <small>
              Mail adresinize gelen 6 haneli doğrulama kodu ile mailinizi
              doğrulayın
            </small>
          </div>
          <InputGroup className="input-group-alternative">
            <InputGroupAddon addonType="prepend">
              <InputGroupText>
                <i className="ni ni-email-83" />
              </InputGroupText>
            </InputGroupAddon>
            <Input
              placeholder="Doğruma kodu"
              type="text"
              name="validationCode"
            />
          </InputGroup>
        </FormGroup>

        <Row className="my-4">
          <Col xs="12">
            <div className="text-center">
              <Button color="primary" type="submit" style={{ width: '75%' }}>
                Doğrula
              </Button>
            </div>
          </Col>
        </Row>
      </Form>
      <Form role="form" onSubmit={handleResendValidationCodeSubmit}>
        <Row>
          <Col xs="12">
            <div className="text-center">
              <Button
                outline
                color="secondary"
                type="submit"
                style={{ width: '75%' }}>
                Kodu Yeniden Gönder
              </Button>
            </div>
          </Col>
        </Row>
      </Form>
    </>
  );
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ loginAfterRegister }, dispatch);

const connector = connect(null, mapDispatchToProps);

export type MailConfirmFormProps = ConnectedProps<typeof connector>;
export default connector(MailConfirmForm);
