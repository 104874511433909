import React, {PropsWithChildren, Suspense} from 'react';
import {RouteObject, useRoutes} from 'react-router-dom';
import {connect} from 'react-redux';

import HowItWorks from 'src/views/pages/HowItWorks';
import MainNavbar from 'src/components/Navbars/MainNavbar';
import MainFooter from 'src/components/Footers/MainFooter';
import Home from 'src/views/Home';
import Brand from 'src/views/pages/Brand';
import Login from 'src/views/pages/Login';
import VerificationRequest from 'src/views/pages/VerificationRequest';
import RegisterPage from 'src/views/pages/RegisterPage';
import Community from 'src/views/pages/Community';
import MailConfirmPage from 'src/views/pages/MailConfirmPage';
import Profile1 from 'src/views/pages/Profile1';
// import ProfileVerificationRequest from 'src/views/pages/ProfileVerificationRequest';
import Profile from 'src/views/pages/Profile';
import Communication from 'src/views/pages/Communication';
import About from 'src/views/pages/About';
import PageNotFound from 'src/views/pages/PageNotFound';
import ResetPassword from 'src/views/pages/ResetPassword';
import PrivateRoute from 'src/routing/PrivateRoute';
import Loading from 'src/components/Loading/Loading';

const AppRoutes: React.FC<AppProps> = (
  props: PropsWithChildren<AppProps>,
): JSX.Element => {
  const routes: RouteObject[] = [
    {
      path: '/',
      index: true,
      element: <Home />,
    },
    {
      path: '/markalar',
      element: <Brand />,
    },
    {
      path: '/topluluklar',
      element: <Community />,
    },
    {
      path: '/iletisim',
      element: <Communication />,
    },
    {
      path: '/hakkimizda',
      element: <About />,
    },
    {
      path: '/giris',
      element: <Login />,
    },
    {
      path: '/dogrulama/:verificationId',
      element: <VerificationRequest />,
    },
    {
      path: '/kayit',
      element: <RegisterPage />,
    },
    {
      path: '/kayit/dogrula',
      element: <MailConfirmPage />,
    },
    {
      path: '/nasil-calisir',
      element: <HowItWorks />,
    },
    {
      path: '/profil',
      element: <PrivateRoute {...props} />,
      children: [
        {
          path: '',
          element: <Profile />,
        },
      ],
    },
    {
      path: '/profil/sorgula/:verificationRequestToken',
      element: <PrivateRoute {...props} />,
      children: [
        {
          path: '/profil/sorgula/:verificationRequestToken',
          element: <Profile />,
        },
      ],
    },
    {
      path: '/profil-eski',
      element: <Profile1 />,
    },
    {
      path: '/sifremi-sifirla/:resetPasswordToken',
      element: <ResetPassword />,
    },
    {
      path: '*',
      element: <PageNotFound />,
    },
  ];

  const element = useRoutes(routes);

  return (
    <Suspense fallback={<Loading />}>
      <MainNavbar {...props} />
      <main className="main-wrapper">{element}</main>
      <MainFooter />
    </Suspense>
  );
};

const mapStateToProps = (state) => {
  return {
    authData: state?.auth,
  };
};

export type AppProps = ReturnType<typeof mapStateToProps>;

export default connect(mapStateToProps)(AppRoutes);
