import React from 'react';

import 'src/assets/css/yetky-theme.scss';
import notfound from 'src/assets/img/theme/404notfound.png';

const PageNotFound: React.FC = (): JSX.Element => {
  return (
    <div className="yetky-page-not-found">
      <img src={notfound} alt="404 not found" />
    </div>
  );
};

export default PageNotFound;
