export const LOG_LEVELS = {
  DEBUG: 4,
  INFO: 3,
  WARN: 2,
  ERROR: 1,
  OFF: 0,
};

export default class Logger {
  static logLevel = this.setLogLevel();

  static setLogLevel(): number {
    switch (process.env.REACT_APP_LOG_LEVEL) {
      case 'debug':
      case 'DEBUG':
        return LOG_LEVELS.DEBUG;
        break;
      case 'info':
      case 'INFO':
        return LOG_LEVELS.INFO;
        break;
      case 'warn':
      case 'WARN':
        return LOG_LEVELS.WARN;
        break;
      case 'error':
      case 'ERROR':
        return LOG_LEVELS.ERROR;
        break;
      default:
        return LOG_LEVELS.OFF;
        break;
    }
  }

  static log(...args: Array<unknown>): void {
    if (this.logLevel >= LOG_LEVELS.DEBUG) {
      console.log(...args); // eslint-disable-line no-console
    }
  }

  static info(...args: Array<unknown>): void {
    if (this.logLevel >= LOG_LEVELS.INFO) {
      console.info(...args); // eslint-disable-line no-console
    }
  }

  static warn(...args: Array<unknown>): void {
    if (this.logLevel >= LOG_LEVELS.WARN) {
      console.warn(...args); // eslint-disable-line no-console
    }
  }

  static error(...args: Array<unknown>): void {
    if (this.logLevel >= LOG_LEVELS.ERROR) {
      console.error(...args); // eslint-disable-line no-console
    }
  }

  set logLevel(level: number) {
    this.logLevel = level;
  }

  get logLevel(): number {
    return this.logLevel;
  }
}
