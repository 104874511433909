import React, { useEffect, useState } from 'react';
import VerificationService from '../../common/services/VerificationService';
import ProfileService from '../../common/services/ProfileService';
// @ts-ignore
import { Alert, Button, Row, Col } from 'reactstrap';
// import VerificationTable from './VerificationTable';
import QRCodeComponent from './QRCodeComponent';
import { useTranslation } from 'src/common/i18n/I18n';
import LoadingForm from '../Loading/LoadingForm';
import { GiTeacher } from 'react-icons/gi';
import { FaGraduationCap } from 'react-icons/fa';
import { FaUserNurse } from 'react-icons/fa';
import { GiPodiumWinner } from 'react-icons/gi';
import { MdMilitaryTech } from 'react-icons/md';

const getIconStyle = () => {
  return {
    fontSize: '2rem',
    justifyContent: 'space-evenly',
    color: 'white',
  };
};

const selectCommunityIcon = (community: string) => {
  switch (community) {
    case 'STUDENT':
      return (
        <Col key="1" className="align-items-center" style={getIconStyle()}>
          <FaGraduationCap />
        </Col>
      );
    case 'TEACHER':
      return (
        <Col key="2" className="align-items-center" style={getIconStyle()}>
          <GiTeacher />
        </Col>
      );
    case 'SPORT':
      return (
        <Col key="3" className="align-items-center" style={getIconStyle()}>
          <GiPodiumWinner />
        </Col>
      );
    case 'MEDICAL PROFESSIONAL':
      return (
        <Col key="4" className="align-items-center" style={getIconStyle()}>
          <FaUserNurse />
        </Col>
      );
    case 'MILITARY':
      return (
        <Col key="5" className="align-items-center" style={getIconStyle()}>
          <MdMilitaryTech />
        </Col>
      );
    default:
      return <div />;
  }
};

const ProfileVerifiedCommunitiesForm = () => {
  const [verifiedCommunities, setVerifiedCommunities] = useState<Array<any>>(
    [],
  );
  const [verificationRequestToken, updateVerificationRequestToken] =
    useState('');
  const { t } = useTranslation();
  const [notification, setNotification] = useState({
    notificationType: 'info',
    notificationShow: false,
    notificationText: t('verify_identity_notifacation'),
  });
  const [progressProperties, setProgressProperties] = useState({
    waitForProcess: false,
  });
  // @ts-ignore
  const [user, updateUser] = useState({
    firstName: '',
    lastName: '',
  });

  // Fetch user from service & display on screen
  const fetchUserData = async () => {
    const response: Response = await ProfileService.getUserInfo();
    if (response.ok) {
      const data = await response.json();
      updateUser(data);
    }
    setProgressProperties({ waitForProcess: true });
  };

  const fetchVerifiedCommunities = async () => {
    const response: Response =
      await VerificationService.getUserVerifiedCommunities();
    if (response.ok) {
      const data: Array<any> = await response.json();
      setVerifiedCommunities([...verifiedCommunities, data]);
      setNotification({
        ...notification,
        notificationShow: true,
        notificationText: t(
          'verify_identity_on_authentication_panel_notification',
        ),
        notificationType: 'info',
      });
    } else {
      setNotification({
        ...notification,
        notificationShow: true,
        notificationType: 'danger',
        notificationText: t('identity_fetch_error_notification'),
      });
    }
    setProgressProperties({ waitForProcess: true });
  };

  useEffect(() => {
    setProgressProperties({ waitForProcess: false });
    setNotification({
      ...notification,
      notificationShow: false,
    });
    fetchVerifiedCommunities();
    fetchUserData();
  }, []);

  const getQrCode = async () => {
    const response: Response =
      await VerificationService.getVerificationTokenCrypt();
    if (response.ok) {
      const data = await response.json();
      updateVerificationRequestToken(data['token']);
      console.log(encodeURIComponent(data['token']));
    } else {
      setNotification({
        ...notification,
        notificationShow: true,
        notificationType: 'danger',
        notificationText: t('qr_code_error_notification'),
      });
    }
  };

  return (
    <>
      {progressProperties.waitForProcess ? (
        <div>
          <div>
            {verifiedCommunities.length > 0 &&
            verifiedCommunities[0].length > 0 ? (
              <Row className="justify-content-center">
                <img
                  src={require('src/assets/img/verification_result/dijital_kimlik.png')}
                  style={{
                    height: 'auto',
                    maxWidth: '60%',
                    minWidth: '19rem',
                    border: '1px solid #d8d7dd',
                    borderTopLeftRadius: '1rem',
                    borderTopRightRadius: '1rem',
                  }}></img>
                <div
                  style={{
                    height: '7rem',
                    width: '60%',
                    minWidth: '19rem',
                    border: '1px solid #d8d7dd',
                    background: '#5b6bc1',
                    borderBottomLeftRadius: '1rem',
                    borderBottomRightRadius: '1rem',
                  }}
                  className="text-center">
                  <div
                    className="text-center"
                    style={{ marginTop: '0.5rem', color: 'white' }}>
                    {user.firstName} {user.lastName}
                  </div>
                  <Row className="justify-content-center">
                    {verifiedCommunities[0].map((contentType) =>
                      selectCommunityIcon(contentType.community.name),
                    )}
                  </Row>
                </div>
              </Row>
            ) : (
              <Alert
                color={notification.notificationType}
                isOpen={notification.notificationShow}>
                <div
                  style={{
                    textAlign: 'center',
                  }}>
                  {notification.notificationText}
                </div>
              </Alert>
            )}
          </div>
          <Col>
            <Row className="justify-content-center">
              <Button
                className="my-3"
                color="primary"
                type="button"
                onClick={() => getQrCode()}>
                {t('qr_code_button')}
              </Button>
            </Row>
            <Row className="justify-content-center">
              {verificationRequestToken !== '' ? (
                <QRCodeComponent
                  qrCode={`https://yetky.com/profil/sorgula/${encodeURIComponent(
                    verificationRequestToken,
                  )}`}
                  width={'10rem'}
                />
              ) : (
                <></>
              )}
            </Row>
          </Col>
        </div>
      ) : (
        <LoadingForm />
      )}
    </>
  );
};

export default ProfileVerifiedCommunitiesForm;
